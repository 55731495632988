import voucherApis from 'shared_js/api/public/vouchers';

export default {
  async fetchClaimedCoupons({ commit, state }, params = {}) {
    if (state.fetchClaimedCouponsStatus === null) {
      return;
    }

    commit('SET_FETCH_CLAIMED_COUPONS_STATUS', null);

    try {
      const response = params.apiResult || (await voucherApis.getClaimedCoupons());
      let status = false;

      if (response.meta.http_status === 200) {
        status = true;
        const { data } = response;

        commit('SET_CLAIMED_COUPONS', data);
      }
      commit('SET_FETCH_CLAIMED_COUPONS_STATUS', status);
    } catch (e) {
      commit('SET_FETCH_CLAIMED_COUPONS_STATUS', false);
    }
  },
};

import addressBooksApis from 'shared_js/api/public/address-books';
import { isLoggedIn } from 'shared_js/helpers/user-helper';

export default {
  async createAddress({ commit, state }, data) {
    try {
      const response = await addressBooksApis.createAddress(data);
      commit('UPDATE_ADDRESS', response.data);
      commit('SET_TOTAL_ADDRESS', state.totalAddress + 1);
      return response;
    } catch (err) {
      throw new Error(err);
    }
  },
  async fetchAddresses({ commit, state }, params = {}) {
    if (!isLoggedIn()) {
      commit('SET_ADDRESS', []);
      commit('SET_TOTAL_ADDRESS', 0);
      commit('SET_FETCH_ADDRESS_STATUS', true);
      return;
    }

    if (state.addresses !== null && state.addresses.length === state.totalAddress) {
      return;
    }

    const offset = state.addresses ? state.addresses.length : 0;

    commit('SET_FETCH_ADDRESS_STATUS', null);

    try {
      const parameter = {
        offset,
        limit: params.limit || 10,
        query: params.query || '',
      };
      const response = await addressBooksApis.getAddresses(parameter);
      let status = false;
      let data = null;
      let total = 0;

      if (response.meta.http_status === 200) {
        status = true;
        ({ data } = response);
        total = response.meta.total || 0;
      }
      commit('SET_ADDRESS', data);
      commit('SET_TOTAL_ADDRESS', total);
      commit('SET_FETCH_ADDRESS_STATUS', status);
    } catch (e) {
      commit('SET_FETCH_ADDRESS_STATUS', false);
      throw e;
    }
  },
};

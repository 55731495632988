export default {
  registered: null,
  shops: null,
  fetchRegisteredAccountStatus: '',
  registerAccountStatus: '',
  registerAccountError: null,
  unregisterAccountStatus: '',
  unregisterAccountError: null,
  enqueueSyncOrdersStatus: {},
  syncInProgressShops: [],
};

import { mapState } from 'vuex';
import { getUserId36, isLoggedIn } from 'shared_js/helpers/user-helper';
import { IS_SSR } from 'shared_js/constant/general';
import userAgentMixins from 'shared_js/mixins/user-agent';

export default {
  mixins: [userAgentMixins],
  computed: {
    isLoggedIn,
    ...mapState('configuration', ['configuration']),
    ...mapState('transactions', ['isNewUser']),
  },
  methods: {
    loadHotjarUserAttributes() {
      if (this.isLoggedIn && global.hj && !IS_SSR) {
        // eslint-disable-next-line func-names
        const userId = getUserId36();
        const { first_transaction_date: firstTransactionDate = null } = this.configuration || {};
        const { from = '', referrer = '' } = this.$route.query;
        const hotjarUserAttributes = {
          'User id': userId,
          'New user': this.isNewUser,
          'First transaction date': firstTransactionDate ? new Date(firstTransactionDate).toISOString() : null,
          'Mitra user': this.isBLMitra,
          Platform: this.platform,
          'Entry point': from,
          Referrer: referrer,
        };
        window.hj('identify', userId, hotjarUserAttributes);
        if (/(local.host|preproduction.bukalapak.com)/.test(window.location.hostname)) {
          console.log('HOTJAR_USER_ATTRIBUTES', hotjarUserAttributes);
        }
      }
    },
  },
};

import { DEFAULT_ERROR_MESSAGE, CONNECTION_ERROR_TYPES } from 'shared_js/constant/bukasend';

export const getErrorMessage = err => {
  let message = DEFAULT_ERROR_MESSAGE;

  if (err.response && err.response.data) {
    const { data } = err.response;

    if (data.errors && data.errors.length > 0) {
      ({ message } = data.errors[0]);
    }
  }

  return message;
};

export const getConnectionErrorType = error => {
  const { message: errorMessage = '' } = error || {};
  if (errorMessage === 'Network Error') {
    return CONNECTION_ERROR_TYPES.OFFLINE;
  }
  if (errorMessage.includes('timeout')) {
    return CONNECTION_ERROR_TYPES.TIMEOUT;
  }
  return CONNECTION_ERROR_TYPES.OTHER;
};

export default {
  getErrorMessage,
};

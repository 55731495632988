/* eslint-disable */
import { IS_DEV_MODE } from 'shared_js/constant/bukasend';
import { IS_SSR } from 'shared_js/constant/general';

const handlerHooks = {
  beforeSuccessResolved(funcName, response) {
    if (IS_DEV_MODE) {
      return console.log('API RESPONSE:', funcName, response);
    }

    return true;
  },
  onError(error, reject) {
    if (IS_SSR) {
      reject(error);
    } else {
      const errorReporter = require('./errorReporter').default;
      errorReporter.apiErrorCatcher(error).catch(finalError => reject(finalError));
    }
  },
};

export default handlerHooks;

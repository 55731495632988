export default {
  transactions: {
    progress: [],
    done: [],
  },
  fetchTransactionsStatus: {
    progress: '',
    done: '',
  },
  limit: 10,
  total: {
    progress: null,
    done: null,
  },
  detailTransactions: {},
};

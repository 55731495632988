export default {
  SET_REGISTERED(state, status) {
    state.registered = status;
  },
  SET_SHOPS(state, shops) {
    state.shops = shops;
  },
  SET_FETCH_REGISTERED_ACCOUNT_STATUS(state, status) {
    state.fetchRegisteredAccountStatus = status;
  },
  SET_REGISTER_ACCOUNT_STATUS(state, status) {
    state.registerAccountStatus = status;
  },
  SET_REGISTER_ACCOUNT_ERROR(state, error) {
    state.registerAccountError = error;
  },
  SET_UNREGISTER_ACCOUNT_STATUS(state, status) {
    state.unregisterAccountStatus = status;
  },
  SET_UNREGISTER_ACCOUNT_ERROR(state, error) {
    state.unregisterAccountError = error;
  },
  SET_ENQUEUE_SYNC_ORDERS_STATUS(state, { shopNames, status }) {
    const newSyncOrderStatus = {};
    shopNames.forEach(shopName => {
      newSyncOrderStatus[shopName] = status;
    });
    state.enqueueSyncOrdersStatus = {
      ...state.enqueueSyncOrdersStatus,
      ...newSyncOrderStatus,
    };
  },
  SET_SYNC_IN_PROGRESS_SHOPS(state, shops) {
    state.syncInProgressShops = [...state.syncInProgressShops, ...shops];
  },
  REMOVE_SYNC_IN_PROGRESS_SHOPS(state, shops) {
    const newSyncInProgressShops = [...state.syncInProgressShops].filter(shop => !shops.includes(shop));
    state.syncInProgressShops = newSyncInProgressShops;
  },
};

export function isBudgetPromoActive(promoType, promoBudgetInfo, togglePromotionBudgetEnabled) {
  return Boolean(
    (togglePromotionBudgetEnabled &&
      (promoBudgetInfo || []).some(promo => promo.promo_type === promoType && promo.state === 'active')) ||
      !togglePromotionBudgetEnabled,
  );
}

export default {
  isBudgetPromoActive,
};

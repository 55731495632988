export default {
  SET_RETURN_SOLUTIONS(state, solutions) {
    state.returnSolutions = solutions;
  },
  SET_FETCHING_RETURN_SOLUTIONS(state, isFetching) {
    state.fetchingReturnSolutions = isFetching;
  },
  SET_RETURN_SHIPPINGS(state, shippings) {
    state.returnShippings = shippings;
  },
  SET_FETCHING_RETURN_SHIPPINGS(state, isFetching) {
    state.fetchingReturnShippings = isFetching;
  },
  SET_RETURN_CONFIGURATIONS(state, configurations) {
    state.returnConfig = configurations;
  },
  SET_FETCHING_RETURN_CONFIGURATIONS(state, isFetching) {
    state.fetchingReturnConfig = isFetching;
  },
};

import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import { isLoggedIn, generateSessionId } from 'shared_js/helpers/user-helper';

import { DEFAULT_TRANSACTION } from '../constants';

export default {
  CREATE_TRANSACTION(state) {
    const transaction = cloneDeep(DEFAULT_TRANSACTION);
    const transactionLength = state.transactions.length;
    let senderAddress = {};

    let index = -1;
    if (transactionLength && !isLoggedIn()) {
      index = transactionLength - 1;
    }

    if (index >= 0) {
      const selectedTransaction = state.transactions[index];
      senderAddress = cloneDeep(selectedTransaction.from);
      transaction.hasSetPickupLocation = senderAddress.geolocation_set_by_user;
      if (selectedTransaction.hasSetPickupLocation && selectedTransaction.originalSenderLocation) {
        const { latitude, longitude } = selectedTransaction.originalSenderLocation;
        senderAddress.latitude = latitude;
        senderAddress.longitude = longitude;
      }
    }

    if (!isLoggedIn()) {
      transaction.isNonLoginInput = true;
    }

    state.activeIdx = null;
    state.transaction = {
      ...transaction,
      ...(index >= 0
        ? {
            from: { ...senderAddress },
          }
        : {}),
    };
  },
  SET_TRANSACTION(state, transaction) {
    state.transaction = transaction;
  },
  SET_ACTIVE_IDX(state, index) {
    state.activeIdx = index;
  },
  SET_ACTIVE_TRANSACTION(state, index) {
    state.activeIdx = index;
    state.transaction = cloneDeep(state.transactions[index]);
  },
  SET_SENDER_ADDRESS(state, data) {
    state.transaction.from = { ...data };
  },
  SET_WEIGHT(state, data) {
    state.transaction.weight = data;
  },
  SET_WEIGHT_UNIT(state, data) {
    state.transaction.weight_unit = data;
  },
  SET_VOLUMETRIC(state, { width, height, length }) {
    state.transaction.volumetric = true;
    state.transaction.width = width;
    state.transaction.height = height;
    state.transaction.length = length;
  },
  SET_COURIER(state, data) {
    state.transaction.courier = { ...data };
  },
  SET_AMOUNT(state, data) {
    state.transaction.amount = Object.assign({}, state.transaction.amount, data);
  },
  SET_SENDER_DETAIL(state, data) {
    state.transaction.from = Object.assign({}, state.transaction.from, data);
  },
  SET_RECIPIENT_DETAIL(state, data) {
    state.transaction.to = Object.assign({}, state.transaction.to, data);
  },
  SET_ORIGINAL_SENDER_LOCATION(state, data) {
    state.transaction.originalSenderLocation = data;
  },
  SET_HAS_SET_PICKUP_LOCATION(state, data) {
    state.transaction.hasSetPickupLocation = data;
  },
  SET_HAS_SET_DESTINATION_LOCATION(state, data) {
    state.transaction.hasSetDestinationLocation = data;
  },
  SET_HAS_CLICK_COURIER_SECTION(state, data) {
    state.transaction.hasClickCourierSection = data;
  },
  SET_DROPSHIP(state, data) {
    state.transaction.dropship = data;
  },
  SAVE_TRANSACTION(state) {
    const idx = state.activeIdx;
    const newTransaction = cloneDeep(state.transaction);
    if (idx !== null) {
      if (newTransaction.id && !isEqual(state.transactions[idx], newTransaction)) {
        newTransaction.id = null;
      }
      state.transactions.splice(idx, 1, newTransaction);
    } else {
      state.transactions.push(newTransaction);
    }
  },
  DELETE_TRANSACTION(state, index) {
    state.transactions.splice(index, 1);
  },
  CLEAR_TRANSACTIONS(state) {
    state.transactions = [];
  },
  SET_SAVE_TRANSACTION_STATUS(state, status) {
    state.saveTransactionStatus = status;
  },
  SET_CREATE_BULK_TRANSACTION_STATUS(state, status) {
    state.createBulkTransactionStatus = status;
  },
  SET_TRANSACTIONS(state, transactions) {
    state.transactions = transactions;
  },
  SET_SHOW_PACKAGE_FORM(state, isShow) {
    state.showPackageForm = isShow;
  },
  SET_INPUT_RECIPIENT_TYPE(state, type) {
    state.transaction.inputRecipientType = type;
  },
  SET_DISTRICTS(state, data) {
    state.transaction.districts = data;
  },
  SET_TRANSACTION_TYPE(state, type) {
    state.transactionType = type;
  },
  SET_LOADED_FROM_STORAGE(state, isLoaded) {
    state.loadedFromStorage = isLoaded;
  },
  SET_FIRST_RENDER_TIME(state, payload = null) {
    state.firstRenderTime = payload;
  },
  SET_PREVIOUS_CLICK(state, data) {
    state.previousClick = data;
  },
  SET_SELECTED_INSURANCE_PRODUCT(state, data) {
    state.transaction.selectedInsuranceProduct = data;
  },
  SET_PICKUP_REQUEST_TIME(state, data) {
    state.transaction.pickup_request_time = data;
  },
  SET_PICKUP_REQUEST_TIME_ERROR(state, error) {
    state.transaction.pickupRequestTimeError = error;
  },
  SET_DETAIL_PICKUP_DATE(state, data) {
    state.transaction.detail_pickup_date = data;
  },
  SET_DETAIL_PICKUP_TIME(state, data) {
    state.transaction.detail_pickup_time = data;
  },
  SET_TRANSACTION_CONTENT_CATEGORY(state, data) {
    state.transaction.contentCategory = data;
  },
  SET_TRANSACTION_NOTES(state, notes) {
    state.transaction.notes = notes;
  },
  SET_REBOOK_AUTO_SUBMIT_DONE(state) {
    if (state.transaction.rebookData) {
      state.transaction.rebookData.autoSubmit = false;
      state.transaction.rebookData.hasAutoSubmitted = true;
    }
  },
  UPDATE_REBOOK_ORIGINAL_SENDER_ADDRESS(state, data) {
    if (state.transactions.length) {
      const firstTransaction = state.transactions[0];
      const { originalFrom } = firstTransaction.rebookData || {};
      if (originalFrom) {
        const updatedTransaction = cloneDeep(firstTransaction);
        const newOriginalFrom = { ...originalFrom, ...data };
        updatedTransaction.rebookData.originalFrom = newOriginalFrom;
        state.transactions.splice(0, 1, updatedTransaction);
      }
    }
    const { originalFrom } = state.transaction.rebookData || {};
    if (originalFrom) {
      const newOriginalFrom = { ...originalFrom, ...data };
      state.transaction.rebookData = {
        ...state.transaction.rebookData,
        originalFrom: newOriginalFrom,
      };
    }
  },
  UPDATE_INSURANCE_AUTO_OPTED_IN(state, value) {
    state.transaction.insuranceAutoOptedIn = value;
  },
  SET_IS_NON_LOGIN_INPUT(state, value) {
    state.transaction.isNonLoginInput = value;
  },
  UPDATE_DRAFT_ORIGINAL_ADDRESS(state, address) {
    if (state.transaction && state.transaction.draftData) {
      const addressField = address.isSenderAddress ? 'originalFrom' : 'originalTo';
      state.transaction.draftData = {
        ...state.transaction.draftData,
        [addressField]: {
          ...state.transaction.draftData[addressField],
          ...address,
        },
      };
    }
  },
  UPDATE_DRAFT_ERROR_STATUS(state, status) {
    if (state.transaction && state.transaction.draftData) {
      state.transaction.draftData = {
        ...state.transaction.draftData,
        isError: status,
      };
    }
  },
  SET_SAVE_DRAFT_STATUS(state, status) {
    state.saveDraftStatus = status;
  },
  SET_TRANSACTIONS_ID_BY_INDEX(state, { index, id }) {
    const newTransaction = cloneDeep(state.transactions[index]);
    newTransaction.id = id;

    state.transactions.splice(index, 1, newTransaction);
  },
  SET_GROUP_ID(state, value) {
    state.groupId = value;
  },
  SET_FORM_SELECTED_VOUCHER(state, value) {
    state.formSelectedVoucher = value;
  },
  SET_VOUCHER_VALIDATE_STATUS(state, value) {
    state.voucherValidateStatus = value;
  },
  SET_TRANSACTIONS_UNIQUE_ID_BY_INDEX(state, { index, uniqueId }) {
    const transaction = cloneDeep(state.transactions[index]);
    transaction.uniqueId = uniqueId;

    state.transactions.splice(index, 1, transaction);
  },
  REGENERATE_TRANSACTIONS_UNIQUE_ID(state) {
    const transactions = cloneDeep(state.transactions);

    transactions
      .filter(trx => !trx.id)
      .map(trx => {
        trx.uniqueId = generateSessionId();

        return trx;
      });

    state.transactions = transactions;
  },
  SET_TRX_COD_COURIERS_COUNT(state, payload) {
    state.transaction.codCouriersCount = payload;
  },
};

import routes from './routes';

// eslint-disable-next-line
export function createRouter({ VueRouter, additionalRouterParams = {} }) {
  return new VueRouter({
    routes,
    mode: 'history',
    base: 'bukasend',
    ...additionalRouterParams,
  });
}

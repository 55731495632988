export default {
  recipientAddresses({ recipientAddresses }, _, { configuration, form }) {
    if (!recipientAddresses) {
      return null;
    }

    const { rebookConfig = {} } = configuration || {};
    const { rebookEnabled = false, resendEnabled = false } = rebookConfig || {};
    if ((rebookEnabled || resendEnabled) && form) {
      let transaction = null;
      if (form.transactions.length) {
        transaction = form.transactions[0];
      } else {
        ({ transaction } = form);
      }
      const { originalTo } = transaction.rebookData || {};
      if (originalTo) {
        return [originalTo, ...recipientAddresses];
      }
    }

    return recipientAddresses;
  },
};

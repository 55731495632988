import { TRANSACTIONS_FILTERS, DEFAULT_TRANSACTIONS_FILTERS } from 'shared_js/constant/bukasend';
import cloneDeep from 'lodash.clonedeep';

export default {
  activeStatus: 'invoices',
  activeTrxFilter: {
    progress: TRANSACTIONS_FILTERS.BUKASEND,
    done: TRANSACTIONS_FILTERS.BUKASEND,
  },
  activeTrxQuery: {
    progress: '',
    done: '',
  },
  drafts: [],
  invoices: [],
  transactions: {
    progress: [],
    done: [],
    invoices: [],
  },
  fetchTransactionsStatus: {
    invoices: '',
    progress: '',
    done: '',
    draft: '',
  },
  limit: 10,
  total: {
    draft: null,
    invoices: null,
    progress: null,
    done: null,
  },
  perPageTransactions: {
    draft: {},
    invoices: {},
    progress: {},
    done: {},
  },
  currentPage: {
    draft: 1,
    invoices: 1,
    progress: 1,
    done: 1,
  },
  searchKeyword: {
    draft: '',
    progress: '',
    done: '',
  },
  transactionFilters: {
    draft: cloneDeep(DEFAULT_TRANSACTIONS_FILTERS),
    progress: cloneDeep(DEFAULT_TRANSACTIONS_FILTERS),
    done: cloneDeep(DEFAULT_TRANSACTIONS_FILTERS),
  },
  paginationLimit: {
    draft: 10,
    progress: 10,
    done: 10,
  },
  paginationLimitOptions: [10, 20, 30, 40, 50],
  detailTransactions: {},
  modals: {
    invoiceModalActive: false,
    deleteModalActive: false,
  },
  isNewUser: null,

  // Summary Report and Recap
  transactionSummary: null,
  transactionSummaryHash: {},
  transactionRecaps: [],
  fetchTransactionSummaryStatus: '',
  fetchTransactionSummaryError: null,
  fetchTransactionRecapsStatus: '',
};

export default {
  SET_CONFIGURATION(state, data) {
    state.configuration = {
      ...(state.configuration || {}),
      ...data,
    };
  },
  SET_FETCH_CONFIG_STATUS(state, status) {
    state.fetchConfigStatus = status;
  },
  SET_INFORMATION(state, data) {
    state.information = data;
  },
  SET_FETCH_INFORMATION_STATUS(state, status) {
    state.fetchInformationStatus = status;
  },
  SET_SESSION_ID(state, payload) {
    state.sessionId = payload;
  },
  RESET_RENDER_TIME(state) {
    state.renderTime = new Date().getTime();
  },
  SET_CUSTOM_FIELD_WHITELIST_USERS(state, payload) {
    state.customFieldWhitelistUsers = payload;
  },
  SET_COURIER_IMAGE_CONFIG(state, payload) {
    state.courierImageConfig = payload;
  },
  SET_ONBOARDINGS(state, payload) {
    state.onboardings = payload;
  },
  SET_MANUAL_BOOKING_COURIER_SERVICES(state, payload) {
    state.manualBookingCourierServices = payload;
  },
  SET_PROMO_CONFIG(state, payload) {
    state.promoConfig = payload;
  },
  SET_PRINT_CONFIG(state, payload) {
    state.printConfig = payload;
  },
  SET_INITIALIZED(state) {
    state.initialized = true;
  },
  SET_ENTRY_POINT(state, payload) {
    state.entryPoint = payload;
  },
  SET_VISITED_ROUTE(state, routeName) {
    if (!state.visitedRoutes.includes(routeName)) {
      state.visitedRoutes = [...state.visitedRoutes, routeName];
    }
  },
  SET_CASHBACK_CONFIG(state, payload) {
    state.cashbackConfig = payload;
  },
  SET_PACKAGE_CONTENT_CATEGORIES(state, payload) {
    state.packageContentCategories = payload;
  },
  SET_GRAB_SLA_CONFIG(state, payload) {
    state.grabSLAConfig = payload;
  },
  SET_DISCREPANCY_DETAILS(state, payload = null) {
    state.discrepancyDetails = payload;
  },
  SET_MISC_CONFIG(state, payload) {
    state.miscConfig = payload;
  },
  SET_REBOOK_CONFIG(state, payload) {
    const {
      rebook_enabled: rebookEnabled,
      resend_enabled: resendEnabled,
      rebookable_courier_services: rebookableCourierServices,
      resendable_courier_services: resendableCourierServices,
      rebook_messages: rebookMessages,
    } = payload;
    state.rebookConfig = {
      rebookEnabled,
      resendEnabled,
      rebookableCourierServices,
      resendableCourierServices,
      rebookMessages,
    };
  },
  SET_ROLE_PERMISSION(state, payload) {
    state.rolePermission = payload;
  },
  SET_CANCELLATION_CONFIG(state, payload) {
    state.cancellationConfig = payload;
  },
  SET_ONDEMAND_COURIER_SERVICES(state, payload) {
    state.ondemandCourierServices = payload;
  },
  SET_MEMBERSHIPS(state, payload) {
    state.memberships = payload;
  },
  SET_VOUCHER_SUGGESTIONS(state, payload) {
    state.voucherSuggestions = payload;
  },
  SET_VOUCHER_CONFIG(state, payload) {
    state.voucherConfig = payload;
  },
  SET_ONGKIR_GOKIL_CONFIG(state, payload) {
    state.ongkirGokilConfig = payload;
  },
  SET_KYC_CONFIG(state, payload) {
    state.kyc = payload;
  },
  SET_ENCYCLOPEDIA_AGENT_CONFIG(state, payload) {
    state.encyclopediaAgentConfig = payload;
  },
  SET_COURIER_LANDING_CONFIG(state, payload) {
    state.courierLandingConfig = payload;
  },
  SET_COD_CONFIG(state, payload) {
    state.codConfig = payload;
  },
  SET_WEIGHT_TNC_CONFIG(state, data) {
    const { tnc } = data;
    state.weightTncConfig = tnc;
  },
  SET_BENEFITS_CONFIG(state, payload) {
    state.benefitsConfig = payload;
  },
  SET_COURIER_SELECTION_CONFIG(state, data) {
    const { couriers } = data;
    state.courierSelectionConfig = couriers;
  },
  SET_COURIER_TAGS(state, payload) {
    state.courierTags = payload;
  },
  /**
   * Since so many repetitive mutations
   * new simple mutation can be used for the future (only for direct assign state value)
   * For debugging, keyword `stateName: dummyState` can be used
   * to search where this mutation is called
   */
  SET_STATE_VALUE(state, { stateName, value }) {
    state[stateName] = value;
  },
};

import {
  checkUserAgent,
  BLMitraVersion,
  BLIosVersion,
  BLAndroidVersion,
  isBLAndroid,
  isBLIos,
  isBLMitra,
  platform,
  isDesktop,
} from 'shared_js/helpers/user-agent-helper';

const userAgentMixins = {
  computed: {
    isBLAndroid,
    isBLIos,
    isBLMitra,
    platform,
    isDesktop,
  },
  methods: {
    checkUserAgent,
    BLMitraVersion,
    BLIosVersion,
    BLAndroidVersion,
  },
};

export default userAgentMixins;

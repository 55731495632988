import { API_BASE_URL } from 'shared_js/constant/bukasend';

const interfaces = {
  getCurrentUser() {
    return this.get(`${API_BASE_URL}/me`, 'user');
  },
  getUserById(id) {
    return this.get(`${API_BASE_URL}/users/${id}`, 'user');
  },
  postKYCUser(data) {
    return this.post(`${API_BASE_URL}/open-shipments/users/kycs`, 'user', { data });
  },
  postKYCUserTnc(data) {
    return this.post(`${API_BASE_URL}/open-shipments/users/kycs/terms-conditions`, 'user', { data });
  },
  registerAgent() {
    return this.post(`${API_BASE_URL}/open-shipments/users/agents`, 'user');
  },
  registerBukasendMitraAgent() {
    return this.post(`${API_BASE_URL}/open-shipments/users/mitra-agents`, 'user');
  },
  retrieveBukasendMitraAgent() {
    return this.get(`${API_BASE_URL}/open-shipments/users/mitra-agents`, 'user');
  },
};

export default interfaces;

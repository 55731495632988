function wrapSecureClientInterface(requestManager, interfaces = {}) {
  const wrappedInterfaces = {};

  Object.keys(interfaces).forEach(key => {
    wrappedInterfaces[key] = (...args) => interfaces[key].bind(requestManager)(...args);
  });

  return wrappedInterfaces;
}

module.exports = wrapSecureClientInterface;

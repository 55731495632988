export default {
  SET_INSURANCE_PRODUCTS(state, data) {
    state.insuranceProducts = data;
  },
  SET_FETCH_INSURANCE_PRODUCTS_STATUS(state, status) {
    state.fetchInsuranceProductsStatus = status;
  },
  SET_LOADING_INSURANCE_PRODUCTS(state, isLoading) {
    state.isLoadingInsuranceProducts = isLoading;
  },

  SET_INSURANCE_TERMS_AND_CONDITIONS(state, data) {
    state.insuranceTermsAndConditions = data;
  },
  SET_FETCH_INSURANCE_TERMS_AND_CONDITIONS_STATUS(state, status) {
    state.fetchInsuranceTermsAndConditionsStatus = status;
  },
  SET_LOADING_INSURANCE_TERMS_AND_CONDITIONS(state, isLoading) {
    state.isLoadingInsuranceTermsAndConditions = isLoading;
  },
};

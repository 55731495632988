<template>
  <div class="c-empty-state u-txt--center">
    <img :src="image">
    <bl-text-av variation="subheading-18" weight="semi-bold" class="u-mrgn-top--5 u-mrgn-bottom--2">{{ title }}</bl-text-av>
    <bl-text-av variation="body-14">{{ description }}</bl-text-av>
    <bl-button-av v-if="action" :fullwidth="actionFullWidth" variation="primary" size="small" class="u-mrgn-top--3" @click="$emit('click-action')">
      {{ action }}
    </bl-button-av>
  </div>
</template>
<script>
import BlTextAv from '@bukalapak/bazaar-mweb/dist/components/BlTextAv';
import BlButtonAv from '@bukalapak/bazaar-mweb/dist/components/BlButtonAv';

export default {
  name: 'EmptyState',
  components: {
    BlTextAv,
    BlButtonAv,
  },
  props: {
    image: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    action: {
      type: String,
      default: '',
    },
    actionFullWidth: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style src="./EmptyState.scss" lang="scss" scoped>

<template>
  <div
    class="u-width--100 u-height-100vh u-bg--white"
    :class="containerClass"
  >
    <bl-header-mv
      border-bottom
      icon="ico_back_android"
      :title="feature"
      class="u-pad-h--3"
      @click="back"
    />

    <empty-state
      action-full-width
      v-bind="emptyStateProps"
      class="u-width-1 u-pad--4 u-mrgn-bottom--80"
    />
    <div class="c-sticky-btn u-position-fixed u-position-fixed--bottom u-width-1 u-bg--white u-pad-v--6 u-pad-h--4">
      <bl-button-av
        fullwidth
        :href="playstoreUrl"
        variation="primary"
      >
        Update sekarang
      </bl-button-av>
    </div>
  </div>
</template>
<script>
import BlButtonAv from '@bukalapak/bazaar-mweb/dist/components/BlButtonAv';
import EmptyState from 'shared_js/components/mobile/EmptyState';

export default {
  name: 'Uncompatibility',
  components: {
    BlButtonAv,
    EmptyState
  },
  props: {
    feature: {
      type: String,
      default: ''
    },
    version: {
      type: String,
      default: ''
    },
    overlay: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default() {
        return `Fitur ${this.feature} hanya bisa digunakan di
        aplikasi versi terbaru v${this.version}. Yuk, update dulu
        aplikasi Mitra kamu di Playstore. Jika belum tersedia, mohon
        cek secara berkala dalam waktu 1-2 minggu ke depan ya.`;
      },
    },
  },
  computed: {
    containerClass() {
      return this.overlay ? 'uncompatibility--overlay' : 'u-position-relative';
    },
    playstoreUrl() {
      return 'https://play.google.com/store/apps/details?id=com.bukalapak.mitra';
    },
    emptyStateProps() {
      const title = 'Yuk, Update Aplikasimu!';
      return {
        title,
        description: this.description,
        image: `${process.env.S4_IMAGE_URL}/m-ast/web-drawable/drawable-hdpi/img_medium_o2o_updateapp.png`,
      };
    },
  },
  methods: {
    back() {
      if( this.overlay ) {
        this.$emit('close-compatibility');
      } else {
        window.location.href = window.location.origin;
      }
    }
  },
};
</script>

<style lang="scss">
  .bl-header__item {
    .bl-button {
      min-height: unset !important;
    }
  }

  #bukasend .c-sticky-btn {
    max-width: 480px;
  }

  .uncompatibility--overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
  }
</style>

import { getErrorMessage } from 'shared_js/helpers/error-helper';
import { DEFAULT_ERROR_MESSAGE } from 'shared_js/constant/bukasend';
import vouchersApis from 'shared_js/api/public/vouchers';

export const checkValidateVoucher = async ({ voucherCode = '', invoiceId = null, transactions = [] }) => {
  const response = {
    isValid: false,
    message: '',
    voucherData: null,
  };

  try {
    const params = {
      invoice_id: invoiceId,
      transactions,
      voucher_codes: [voucherCode],
      skip_payment_validation: true,
      payment_details: {
        types: [],
      },
    };
    const { data, meta } = await vouchersApis.validate(params);
    const validVoucher = data.benefits.find(v => v.voucher_code === voucherCode);
    if (validVoucher) {
      response.isValid = true;
      response.voucherData = validVoucher;
    } else {
      const invalidVoucher = data.ineligible_voucher.find(v => v.voucher_code === voucherCode) || {};

      response.isValid = false;
      response.message = invalidVoucher.message || meta.message || DEFAULT_ERROR_MESSAGE;
    }
  } catch (e) {
    response.isValid = false;
    response.message = getErrorMessage(e);
  }

  return response;
};

export default {
  checkValidateVoucher,
};

import userApis from 'shared_js/api/public/users';
import agentApis from 'shared_js/api/public/agents';

export default {
  async fetchCurrentUser({ commit, state }) {
    if (state.profile !== null) {
      return;
    }

    commit('SET_FETCH_STATUS', null);

    try {
      const response = await userApis.getCurrentUser();
      let status = false;

      if (response.meta.http_status === 200) {
        status = true;
        const { data } = response;

        commit('SET_PROFILE', data);
      }
      commit('SET_FETCH_STATUS', status);
    } catch (e) {
      commit('SET_FETCH_STATUS', false);
    }
  },
  async fetchMitraAgent({ commit, state }, params = {}) {
    const { forceReload } = params;
    if (!forceReload && state.mitraAgent !== null) {
      return;
    }

    commit('SET_FETCH_MITRA_AGENT_STATUS', null);

    try {
      const response = params.apiResult || (await agentApis.getMe());
      let status = false;

      if (response.meta.http_status === 200) {
        status = true;
        const { data } = response;

        commit('SET_MITRA_AGENT', data);
      }
      commit('SET_FETCH_MITRA_AGENT_STATUS', status);
    } catch (e) {
      commit('SET_FETCH_MITRA_AGENT_STATUS', false);
    }
  },
  async fetchBukasendMitraAgent({ commit, state }, params = {}) {
    const { forceReload } = params;
    if (!forceReload && state.bukasendMitraAgent !== null) {
      return;
    }

    commit('SET_FETCH_BUKASEND_MITRA_AGENT_STATUS', null);

    try {
      const response = params.apiResult || (await userApis.retrieveBukasendMitraAgent());
      let status = false;

      if (response.meta.http_status === 200) {
        status = true;
        const { data } = response;

        commit('SET_BUKASEND_MITRA_AGENT', data);
      }
      commit('SET_FETCH_BUKASEND_MITRA_AGENT_STATUS', status);
    } catch (e) {
      commit('SET_FETCH_BUKASEND_MITRA_AGENT_STATUS', false);
    }
  },
};

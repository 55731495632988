import aggregateApis from 'shared_js/api/public/aggregate';

export default {
  async fetchReturnConfiguration({ commit, state }, params = {}) {
    const { discussionId, isBuyer, forceReload } = params;
    if (!forceReload && (!discussionId || Object.keys(state.returnConfig).length)) {
      return;
    }

    commit('SET_FETCHING_RETURN_CONFIGURATIONS', true);
    try {
      const aggregates = {
        transaction: {
          method: 'GET',
          path: `/open-shipments/transactions/discussions/${discussionId}?return_type=${isBuyer ? 'buyer' : 'seller'}`,
        },
        solutions: {
          method: 'GET',
          path: `/returns/${discussionId}/solutions`,
        },
      };
      const response = await aggregateApis.aggregate(aggregates);
      const { data, meta } = response;

      const results = Object.keys(data).reduce((result, key) => {
        let outputs = result;
        if (meta[key].http_status === 200) {
          outputs = {
            ...result,
            [key]: data[key],
          };
        }
        return outputs;
      }, {});
      commit('SET_RETURN_CONFIGURATIONS', results);
    } catch (err) {
      throw err;
    } finally {
      commit('SET_FETCHING_RETURN_CONFIGURATIONS', false);
    }
  },
};

const curry = require('../curry-helper');

const getHostname = () => curry(['serverApp', 'hostname'], global) || curry(['location', 'hostname'], window) || '';
const getOrigin = () => curry(['serverApp', 'origin'], global) || curry(['location', 'origin'], window) || '';
const getAccountUrl = ({ comeback = '', path = '' } = {}) => {
  const isPreprod = getHostname().match('preproduction') !== null;
  let url = `https://accounts${isPreprod ? '.preproduction' : ''}.bukalapak.com/${path}`;
  if (comeback) {
    const base = getOrigin();
    let comebackUrl = '';
    try {
      comebackUrl = new URL(comeback, base);
    } catch {
      comebackUrl = comeback;
    }
    const encodedComebackPath = encodeURIComponent(comebackUrl);
    url = `${url}?comeback=${encodedComebackPath}`;
  }
  return url;
};

const getUrlRegister = url => getAccountUrl({ comeback: url, path: 'register' });

const getUrlLogin = url => getAccountUrl({ comeback: url, path: 'login' });

const getLogoutUrl = ({ comeback = '' } = {}) => getAccountUrl({ comeback, path: 'logout' });

const getSubdomain = () => {
  const regex = getHostname().match(/^(mitra|www|m)./) || [];

  return regex[1] || 'www';
};

const isMitraSubdomain = () => getSubdomain() === 'mitra';

const isMobile = () => {
  const serverAppIsMobile = curry(['serverApp', 'isMobile'], global);
  const serverAppIsWebview = curry(['serverApp', 'isWebview'], global);
  const isMobileWeb = (serverAppIsMobile !== null ? serverAppIsMobile : curry(['APP', 'isMobile'], window)) || false;
  const isWebview = (serverAppIsWebview !== null ? serverAppIsWebview : curry(['APP', 'isWebview'], window)) || false;

  if (isMitraSubdomain()) {
    return isMobileWeb || isWebview;
  }

  return getSubdomain() === 'm';
};

const isValidUrl = urlString => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};

const getMHost = () => {
  const origin = getOrigin();

  return origin.replace(/\/\/(www|m|mitra)\./, '//m.');
};

const getWwwHost = () => {
  const origin = getOrigin();

  return origin.replace(/\/\/(www|m|mitra)\./, '//www.');
};

const getMarketplaceHost = () => (isMobile() ? getMHost() : getWwwHost());

const getInvoiceDetailUrl = invoiceId => `${getMarketplaceHost()}/payment/invoices/${invoiceId}?type=buka-pengiriman`;

const getCheckoutUrl = paymentId =>
  `${getMarketplaceHost()}/payment/invoices/${paymentId}/adjust_payment?type=buka-pengiriman`;

const getKYCUrl = () => `${getMHost()}/skyc/kyc-webview?kyc_source=BukaSend`;

const removeTrailingSlash = path => (path ? path.replace(/\/+$/, '') : '');

module.exports = {
  getUrlRegister,
  getUrlLogin,
  getLogoutUrl,
  isMitraSubdomain,
  isMobile,
  isValidUrl,
  getMHost,
  getMarketplaceHost,
  getInvoiceDetailUrl,
  getCheckoutUrl,
  getKYCUrl,
  removeTrailingSlash,
};

import { API_BASE_URL } from 'shared_js/constant/general';

const TNC_TYPE = 'bukasend-tnc';

const interfaces = {
  getInsuranceTermsAndConditions() {
    return this.get(`${API_BASE_URL}/insurances/extra-informations/${TNC_TYPE}`, 'public');
  },
};

export default interfaces;

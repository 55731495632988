import promoBudgetApis from 'shared_js/api/public/promo-budget';

export default {
  async fetchPromoBudgetInfo({ commit, state }, params = {}) {
    const { forceReload } = params;
    if (!forceReload && state.promoBudgetInfo !== null) {
      return;
    }

    commit('SET_FETCH_PROMO_BUDGET_INFO_STATUS', null);

    try {
      const response = params.apiResult || (await promoBudgetApis.getPromoBudgetInfo());
      let status = false;

      if (response.meta.http_status === 200) {
        status = true;
        const { data } = response;

        commit('SET_PROMO_BUDGET_INFO', data);
      }
      commit('SET_FETCH_PROMO_BUDGET_INFO_STATUS', status);
    } catch (e) {
      commit('SET_FETCH_PROMO_BUDGET_INFO_STATUS', false);
    }
  },
};

export default {
  SET_ADDRESS(state, data) {
    state.addresses = data;
  },
  UPDATE_ADDRESS(state, data) {
    const { addresses } = state;
    const index = addresses.findIndex(address => address.id === data.id);
    if (index >= 0) {
      addresses[index] = data;
    } else {
      addresses.push(data);
    }

    state.addresses = addresses;
  },
  DELETE_ADDRESS(state, id) {
    const index = state.addresses.findIndex(address => address.id === id);
    state.addresses.splice(index, 1);
  },
  SET_FETCH_ADDRESS_STATUS(state, status) {
    state.fetchAddressStatus = status;
  },
  SET_PROVINCES(state, data) {
    state.provinces = data;
  },
  SET_FETCH_PROVINCES_STATUS(state, status) {
    state.fetchProvincesStatus = status;
  },
  SET_CITIES(state, { province, cities }) {
    state.cities[province] = cities;
  },
  SET_FETCH_CITIES_STATUS(state, status) {
    state.fetchCitiesStatus = status;
  },
  SET_DISTRICTS(state, { city, districts }) {
    state.districts[city] = districts;
  },
  SET_FETCH_DISTRICTS_STATUS(state, status) {
    state.fetchDistrictsStatus = status;
  },
  SET_POSTAL_CODES(state, { district, postalCodes }) {
    state.postalCodes[district] = postalCodes;
  },
  CLEAR_POSTAL_CODES(state) {
    state.postalCodes = {};
  },
  SET_FETCH_POSTAL_CODES_STATUS(state, status) {
    state.fetchPostalCodesStatus = status;
  },
  SET_GEOLOCATION_FLAG_HASH(state, { sender_address_id: id, geolocation_set_by_user: setByUser }) {
    state.geolocationFlagHash[id] = setByUser;
  },
  SET_FETCH_BULK_ADDITIONAL_ATTRIBUTES_STATUS(state, status) {
    state.fetchBulkAdditionalAttributesStatus = status;
  },
  DELETE_GEOLOCATION_FLAG_HASH(state, id) {
    const newGeolocationFlagHash = { ...state.geolocationFlagHash };
    delete newGeolocationFlagHash[id];
    state.geolocationFlagHash = newGeolocationFlagHash;
  },
};

import UserAgentMixins from 'shared_js/mixins/user-agent';
import { mapState, mapGetters } from 'vuex';
import { isLoggedIn } from 'shared_js/helpers/user-helper';

const DESKTOP_MODE_ROUTES = [
  'form_page',
  'send_at_once_page',
  'shopify_extension_landing',
  'woocommerce_extension_landing',
  'courier_landing_page',
  'fulfillment_landing_page',
  'b2c_landing_page',
  'agent_landing_page',
  'why_landing_page',
  'kyc_page',
  'mitra_wallet_page',
];

export default {
  mixins: [UserAgentMixins],
  computed: {
    isLoggedIn,
    ...mapState('toggle', [
      'toggleBukasendDesktop',
      'toggleHomeDwebEnabled',
      'toggleProfileDwebEnabled',
      'toggleTransactionsPageDwebEnabled',
      'toggleDeliveryCostCheckDwebEnabled',
    ]),
    ...mapGetters('experiment', ['isNewLandingPageActive']),
    isDesktopMode() {
      const desktopModeRoutes = [...DESKTOP_MODE_ROUTES];
      if (this.toggleHomeDwebEnabled) {
        desktopModeRoutes.push('landing_page');
        desktopModeRoutes.push('home_page');
      }
      if (this.toggleProfileDwebEnabled) {
        desktopModeRoutes.push('profile_page');
      }
      if (this.toggleTransactionsPageDwebEnabled) {
        desktopModeRoutes.push('transactions_page');
      }
      if (this.toggleDeliveryCostCheckDwebEnabled && this.isLoggedIn) {
        desktopModeRoutes.push('delivery_cost_check_page');
      }

      const isNewLandingPageDesktop = Boolean(
        this.isNewLandingPageActive && ['landing_page'].includes(this.$route.name),
      );

      return (
        (desktopModeRoutes.includes(this.$route.name) || isNewLandingPageDesktop) &&
        this.platform === 'desktop' &&
        !!this.toggleBukasendDesktop
      );
    },
  },
};

import deliveryInsuranceApis from 'shared_js/api/exclusive/delivery-insurance';
import insuranceTermsAndConditionsApis from 'shared_js/api/public/insurance-terms-and-conditions';

export default {
  async fetchProductInsurance({ state, commit, rootState }) {
    if (state.fetchInsuranceProductsStatus === true) {
      return;
    }

    try {
      commit('SET_LOADING_INSURANCE_PRODUCTS', true);
      const params = {
        amount: Number(rootState.form.transaction.order_price),
      };

      const response = await deliveryInsuranceApis.getProductInsurances(params);
      let status = false;

      if (response.meta.http_status === 200) {
        status = true;
        const { data } = response;

        commit('SET_INSURANCE_PRODUCTS', data);
      }
      commit('SET_FETCH_INSURANCE_PRODUCTS_STATUS', status);
    } catch (error) {
      commit('SET_FETCH_INSURANCE_PRODUCTS_STATUS', false);
    } finally {
      commit('SET_LOADING_INSURANCE_PRODUCTS', false);
    }
  },

  async fetchInsuranceTermsAndConditions({ commit }) {
    try {
      commit('SET_LOADING_INSURANCE_TERMS_AND_CONDITIONS', true);
      const response = await insuranceTermsAndConditionsApis.getInsuranceTermsAndConditions();
      let status = false;

      if (response.meta.http_status === 200) {
        status = true;
        const { data } = response;

        commit('SET_INSURANCE_TERMS_AND_CONDITIONS', data);
      }
      commit('SET_FETCH_INSURANCE_TERMS_AND_CONDITIONS_STATUS', status);
    } catch (error) {
      commit('SET_FETCH_INSURANCE_TERMS_AND_CONDITIONS_STATUS', false);
    } finally {
      commit('SET_LOADING_INSURANCE_TERMS_AND_CONDITIONS', false);
    }
  },
};

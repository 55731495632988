import address from 'shared_js/store/address';
import configuration from './configuration';
import user from './user';
import recipient from './recipient';
import courier from './courier';
import form from './form';
import toggle from './toggle';
import transactions from './transactions';
import madoTransactions from './mado-transactions';
import returns from './returns';
import shopifyExtension from './shopify-extension';
import marketplaceTransactions from './marketplace-transactions';
import deliveryInsurance from './delivery-insurance';
import experiment from './experiment';
import addressBooks from './address-books';
import promoBudget from './promo-budget';
import voucher from './voucher';

function createStore(Vuex) {
  return new Vuex.Store({
    modules: {
      address,
      configuration,
      user,
      recipient,
      courier,
      form,
      toggle,
      transactions,
      madoTransactions,
      returns,
      marketplaceTransactions,
      deliveryInsurance,
      experiment,
      shopifyExtension,
      addressBooks,
      promoBudget,
      voucher,
    },
  });
}

export default createStore;

/**
 * Create headers object with Bukalapak-Identity key
 * and device identity as value
 */
function getBukalapakIdentityHeader() {
  const deviceId = global.deviceIdentity;
  const headers = { 'Bukalapak-Identity': deviceId };
  return headers;
}

export default getBukalapakIdentityHeader;

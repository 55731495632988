export default {
  modifiedAddresses({ addresses }, _, { configuration, form, toggle }) {
    if (!addresses) {
      return null;
    }

    const modifiedAddresses = [...addresses];
    const { rebookConfig = {} } = configuration || {};
    const { rebookEnabled = false, resendEnabled = false } = rebookConfig || {};
    if ((rebookEnabled || resendEnabled) && form) {
      const transaction = form.transactions.length ? form.transactions[0] : form.transaction;
      const { originalTo = null } = transaction.rebookData || {};
      if (originalTo) {
        modifiedAddresses.unshift(originalTo);
      }
    }

    const { toggleSendBulkDraftEnabled = false } = toggle || {};
    if (toggleSendBulkDraftEnabled) {
      const { transaction } = form;
      const { originalFrom = null, originalTo } = transaction.draftData || {};
      if (originalFrom) {
        modifiedAddresses.unshift(originalFrom);
      }
      if (originalTo) {
        modifiedAddresses.unshift(originalTo);
      }
    }

    return modifiedAddresses;
  },
};

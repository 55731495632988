import { TRANSACTIONS_FILTERS } from 'shared_js/constant/bukasend';

export default {
  isTrxActive(state) {
    return state.activeStatus === 'progress';
  },
  activeTrxFilter(state) {
    return state.activeTrxFilter[state.activeStatus];
  },
  isMadoTrxFilterActive(_, { activeTrxFilter }) {
    return activeTrxFilter === TRANSACTIONS_FILTERS.MADO;
  },
  transactions(state) {
    return state.transactions[state.activeStatus];
  },
  fetchTransactionsStatus(state) {
    return state.fetchTransactionsStatus[state.activeStatus];
  },
  total(state) {
    return state.total[state.activeStatus];
  },
  currentPage(state) {
    return state.currentPage[state.activeStatus];
  },
  searchKeyword(state) {
    return state.searchKeyword[state.activeStatus];
  },
  perPageTransactions(state) {
    const perPageTransactions = state.perPageTransactions[state.activeStatus];
    const currentPage = state.currentPage[state.activeStatus];
    if (perPageTransactions && Object.keys(perPageTransactions).length && currentPage) {
      return perPageTransactions[currentPage];
    }
    return null;
  },
  paginationLimit(state) {
    return state.paginationLimit[state.activeStatus] || 10;
  },
  transactionFilters(state) {
    return state.transactionFilters[state.activeStatus] || {};
  },
  isFiltering(_, { searchKeyword, transactionFilters }) {
    return Boolean(
      searchKeyword ||
        Object.values(transactionFilters).some(value =>
          typeof value === 'object' && value !== null ? value.length : value,
        ),
    );
  },
};

import { API_BASE_URL } from 'shared_js/constant/bukasend';

const EXCLUSIVE_URL = `${API_BASE_URL}/_exclusive/experiments`;

const aggregateUrl = `${API_BASE_URL}/aggregate`;

const interfaces = {
  getAlternative(experimentName, data) {
    return this.post(`${EXCLUSIVE_URL}/${experimentName}/participants`, 'public', { data });
  },
  scoreMetric(data) {
    return this.post(`${EXCLUSIVE_URL}/${data.experimentName}/participants/${data.participant}/metrics`, 'public', {
      data,
    });
  },
  bulkScoreMetric(metrics) {
    const aggregate = metrics.reduce(
      (res, metric) => ({
        ...res,
        [metric.metric]: {
          method: 'POST',
          path: `/_exclusive/experiments/${metric.experimentName}/participants/${metric.participant}/metrics`,
          body: {
            metric: metric.metric,
            value: metric.value,
          },
        },
      }),
      {},
    );

    return this.post(aggregateUrl, 'public', {
      data: {
        aggregate,
      },
    });
  },
};

export default interfaces;

import madoApis from 'shared_js/api/public/mado';

export default {
  async fetchTransactions({ commit, state, getters, rootState, rootGetters }, parameters = {}) {
    if (getters.fetchTransactionsStatus === null || getters.transactions.length === getters.total) {
      return;
    }

    const { activeStatus } = rootState.transactions;

    commit('SET_FETCH_TRANSACTIONS_STATUS', { activeStatus, status: null });

    try {
      const { limit } = state;
      const offset = getters.transactions.length;
      const active = Boolean(rootGetters['transactions/isTrxActive']);
      const params = {
        ...parameters,
        offset,
        limit,
        ...(!parameters.fetchAll && { active }),
      };
      const response = await madoApis.retrieveBookings(params);
      const { meta } = response;
      let status = false;

      if (meta.http_status === 200) {
        status = true;
        const { data } = response;

        if (data && data.length > 0) {
          const transactions = {};
          const transactionIds = data.map(trx => {
            transactions[trx.id] = trx;
            return trx.id;
          });
          commit('SET_DETAIL_TRANSACTIONS', transactions);
          commit('APPEND_TRANSACTIONS', { activeStatus, transactionIds });
        }

        if (!parameters.fetchAll) {
          commit('SET_TOTAL_PAGE', { activeStatus, total: meta.total });
        }
      }
      commit('SET_FETCH_TRANSACTIONS_STATUS', { activeStatus, status: parameters.fetchAll ? '' : status });
    } catch (e) {
      commit('SET_FETCH_TRANSACTIONS_STATUS', { activeStatus, status: false });
    }
  },
};

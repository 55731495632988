import experimentApis from 'shared_js/api/exclusive/experiments';
import { getUserId } from 'shared_js/helpers/user-helper';
import { SET_EXPERIMENT, SET_FETCH_EXPERIMENT_STATUS } from './types';

export default {
  async setCleanupCTAExperiment({ commit, state, getters }, params = {}) {
    if (state.experiments[getters.bukasendCleanupCTAExperimentKey]) {
      return;
    }

    commit(SET_FETCH_EXPERIMENT_STATUS, {
      key: getters.bukasendCleanupCTAExperimentKey,
      status: null,
    });

    try {
      const response = params.apiResult;
      let status = false;

      if (response.meta.http_status === 200) {
        status = true;
        const { data } = response;

        commit(SET_EXPERIMENT, { [getters.bukasendCleanupCTAExperimentKey]: data.alternative });
      } else {
        commit(SET_EXPERIMENT, { [getters.bukasendCleanupCTAExperimentKey]: 'control' });
      }
      commit(SET_FETCH_EXPERIMENT_STATUS, {
        key: getters.bukasendCleanupCTAExperimentKey,
        status,
      });
    } catch (e) {
      commit(SET_FETCH_EXPERIMENT_STATUS, {
        key: getters.bukasendCleanupCTAExperimentKey,
        status: false,
      });
    }
  },
  async fetchHomeVoucherBannerExperiment({ commit, getters }) {
    const key = getters.homeVoucherBannerExperimentKey;

    if (getters.homeVoucherBannerExperiment) {
      commit(SET_FETCH_EXPERIMENT_STATUS, { key, status: true });
      return;
    }

    commit(SET_FETCH_EXPERIMENT_STATUS, { key, status: null });

    try {
      let status = false;
      const userId = getUserId().toString() || '0';
      const response = await experimentApis.getAlternative(key, { participant: userId });

      if (response.meta.http_status === 200) {
        status = true;
        const { data } = response;
        commit(SET_EXPERIMENT, { [key]: data.alternative });
      } else {
        commit(SET_EXPERIMENT, { [key]: 'control' });
      }

      commit(SET_FETCH_EXPERIMENT_STATUS, { key, status });
    } catch (e) {
      commit(SET_FETCH_EXPERIMENT_STATUS, { key, status: false });
    }
  },
  async fetchCostCheckParentPageExperiment({ commit, getters }, generatedUserId) {
    const key = getters.bukasendCostCheckParentPageExperimentKey;

    if (getters.bukasendCostCheckParentPage) {
      commit(SET_FETCH_EXPERIMENT_STATUS, { key, status: true });
      return;
    }

    commit(SET_FETCH_EXPERIMENT_STATUS, { key, status: null });

    try {
      let status = false;
      const response = await experimentApis.getAlternative(key, { participant: generatedUserId });

      if (response.meta.http_status === 200) {
        status = true;
        const { data } = response;
        commit(SET_EXPERIMENT, { [key]: data.alternative });
      } else {
        commit(SET_EXPERIMENT, { [key]: 'control' });
      }

      commit(SET_FETCH_EXPERIMENT_STATUS, { key, status });
    } catch (e) {
      commit(SET_FETCH_EXPERIMENT_STATUS, { key, status: false });
    }
  },
};

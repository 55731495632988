import { SET_EXPERIMENT, SET_FETCH_EXPERIMENT_STATUS } from './types';

export default {
  [SET_EXPERIMENT](state, data) {
    state.experiments = {
      ...state.experiments,
      ...data,
    };
  },
  [SET_FETCH_EXPERIMENT_STATUS](state, { key, status }) {
    state.fetchExperimentStatus[key] = status;
  },
};

import { API_BASE_URL } from 'shared_js/constant/general';
import { getOTPHeader } from 'shared_js/helpers/otp-helper';

const interfaces = {
  getAddress(id) {
    return this.get(`${API_BASE_URL}/addresses/${id}`, 'user');
  },
  getAddresses() {
    return this.get(`${API_BASE_URL}/addresses`, 'user');
  },
  getProvinces(params) {
    return this.get(`${API_BASE_URL}/address-infos/provinces`, 'user', { params });
  },
  getCities(params) {
    return this.get(`${API_BASE_URL}/address-infos/cities`, 'user', { params });
  },
  getDistricts(params) {
    return this.get(`${API_BASE_URL}/address-infos/districts`, 'user', { params });
  },
  getPostalCodes(params) {
    return this.get(`${API_BASE_URL}/address-infos/postal-codes`, 'user', { params });
  },
  createAddress(data) {
    return this.post(`${API_BASE_URL}/addresses`, 'user', { data });
  },
  updateAddress(data) {
    const headers = getOTPHeader();
    return this.patch(`${API_BASE_URL}/addresses/${data.id}`, 'user', { data, headers });
  },
  deleteAddress(id) {
    return this.delete(`${API_BASE_URL}/addresses/${id}`, 'user');
  },
};

export default interfaces;

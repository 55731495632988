/* eslint-disable */
import curry from '../../../../shared/javascripts/helpers/curry-helper';
import { NEO_CONFIG_KEYS } from '../../../../shared/javascripts/constant/toggles';

const processingResponse = (app, key, payload, meta, mapping) => {
  const { mutationName, actionName, callback, payloadPath = [], hook = value => value, defaultConfig = null } = mapping[
    key
  ];
  const data = curry(payloadPath, payload) || defaultConfig;

  if (mutationName) {
    app.$store.commit(mutationName, hook(data, meta));
  } else if (actionName) {
    app.$store.dispatch(actionName, hook(data, meta));
  }

  if (callback) {
    callback(data, meta);
  }
};

function initStoreData({ neoToggles, neoConfigs, otherRequests, app, path }) {
  const {
    BUKASEND_CASHBACK_LABEL_DEFAULT,
    BUKASEND_PACKAGE_CONTENT_CATEGORIES_DEFAULT,
    BUKASEND_MISC_CONFIG_DEFAULT,
    BUKASEND_REBOOK_CONFIG_DEFAULT,
    BUKASEND_ONDEMAND_COURIER_SERVICES_DEFAULT,
    BUKASEND_ONGKIR_GOKIL_CONFIG_DEFAULT,
  } = require('../../../../shared/javascripts/constant/bukasend');

  // init neo toggles and additional fetch api
  neoToggles.toggles.forEach(({ key, status }) => {
    app.$store.commit('toggle/SET_TOGGLE_STATUS', { key, status });
  });

  const mappingTogglesAdditional = {
    setFirstTransactions: {
      mutationName: 'transactions/SET_IS_NEW_USER',
      hook: data => data && data.length === 0,
    },
  };

  neoToggles.additional.forEach(({ key, data }) => {
    processingResponse(app, key, data, null, mappingTogglesAdditional);
  });

  // // init neo configs and other fetch api
  const mappingConfigsData = {
    [NEO_CONFIG_KEYS.BUKASEND_AVAILABLE_COURIER_CONFIG]: {
      mutationName: 'configuration/SET_COURIER_IMAGE_CONFIG',
      defaultConfig: {
        mweb: 'https://s3.bukalapak.com/attachment/841012/bukasend-mweb-active-courier-logo.png',
      },
    },
    [NEO_CONFIG_KEYS.BUKASEND_PROMO_CONFIG]: {
      mutationName: 'configuration/SET_PROMO_CONFIG',
    },
    [NEO_CONFIG_KEYS.BUKASEND_CUSTOM_FIELD_WHITELIST_USERS]: {
      mutationName: 'configuration/SET_CUSTOM_FIELD_WHITELIST_USERS',
      payloadPath: ['user_ids'],
    },
    [NEO_CONFIG_KEYS.BUKASEND_PRINT_CONFIG]: {
      mutationName: 'configuration/SET_PRINT_CONFIG',
    },
    [NEO_CONFIG_KEYS.BUKASEND_EXCLUDE_PICKUP_FAILED_COURIERS]: {
      mutationName: 'courier/SET_EXCLUDE_PICKUP_FAILED_COURIERS',
    },
    [NEO_CONFIG_KEYS.BUKASEND_CASHBACK_LABEL]: {
      mutationName: 'configuration/SET_CASHBACK_CONFIG',
      defaultConfig: BUKASEND_CASHBACK_LABEL_DEFAULT,
    },
    [NEO_CONFIG_KEYS.BUKASEND_PACKAGE_CONTENT_CATEGORIES]: {
      mutationName: 'configuration/SET_PACKAGE_CONTENT_CATEGORIES',
      defaultConfig: BUKASEND_PACKAGE_CONTENT_CATEGORIES_DEFAULT,
      payloadPath: ['categories'],
    },
    [NEO_CONFIG_KEYS.BUKASEND_GRAB_SLA_CONFIG]: {
      mutationName: 'configuration/SET_GRAB_SLA_CONFIG',
    },
    [NEO_CONFIG_KEYS.BUKASEND_MISC_CONFIG]: {
      mutationName: 'configuration/SET_MISC_CONFIG',
      defaultConfig: BUKASEND_MISC_CONFIG_DEFAULT,
    },
    [NEO_CONFIG_KEYS.BUKASEND_REBOOK_CONFIG]: {
      mutationName: 'configuration/SET_REBOOK_CONFIG',
      defaultConfig: BUKASEND_REBOOK_CONFIG_DEFAULT,
    },
    [NEO_CONFIG_KEYS.BUKASEND_COURIER_OPS_TIME_CONFIG]: {
      mutationName: 'courier/SET_COURIER_OPS_TIME_CONFIG',
      defaultConfig: {
        operationalTime: {
          'Grab Instant': {
            hour_end: 22,
            hour_end_jabodetabek: null,
            hour_start: 6,
            hour_start_jabodetabek: null,
          },
          'Grab Same Day': {
            hour_end: 16,
            hour_end_jabodetabek: null,
            hour_start: 9,
            hour_start_jabodetabek: null,
          },
          'Rocket Delivery': {
            hour_end: 22,
            hour_end_jabodetabek: null,
            hour_start: 6,
            hour_start_jabodetabek: null,
          },
        },
        warningTemplate: 'Kamu hanya bisa memesan driver pada layanan ini dari jam {{opstime}}.',
        backendOpsTimeCouriers: ['Grab Instant', 'Grab Same Day', 'Rocket Delivery'],
      },
    },
    [NEO_CONFIG_KEYS.BUKASEND_CANCELLATION_CONFIG]: {
      mutationName: 'configuration/SET_CANCELLATION_CONFIG',
    },
    [NEO_CONFIG_KEYS.BUKASEND_ONDEMAND_COURIER_SERVICES]: {
      mutationName: 'configuration/SET_ONDEMAND_COURIER_SERVICES',
      defaultConfig: BUKASEND_ONDEMAND_COURIER_SERVICES_DEFAULT,
      payloadPath: ['courier_services'],
    },
    [NEO_CONFIG_KEYS.BUKASEND_VOUCHER_CONFIG]: {
      mutationName: 'configuration/SET_VOUCHER_CONFIG',
      defaultConfig: {
        filter: {
          default: [],
        },
      },
    },
    [NEO_CONFIG_KEYS.BUKASEND_ONGKIR_GOKIL_CONFIG]: {
      mutationName: 'configuration/SET_ONGKIR_GOKIL_CONFIG',
      defaultConfig: BUKASEND_ONGKIR_GOKIL_CONFIG_DEFAULT,
    },
    [NEO_CONFIG_KEYS.BUKASEND_MANUAL_BOOKING_COURIER_SERVICES]: {
      mutationName: 'configuration/SET_MANUAL_BOOKING_COURIER_SERVICES',
      payloadPath: ['courier_services'],
    },
    [NEO_CONFIG_KEYS.BUKASEND_PICKUP_GUARANTEE_CONFIG]: {
      mutationName: 'courier/SET_PICKUP_GUARANTEE_CONFIG',
    },
    [NEO_CONFIG_KEYS.BUKASEND_ENCYCLOPEDIA_AGENT_CONFIG]: {
      mutationName: 'configuration/SET_ENCYCLOPEDIA_AGENT_CONFIG',
    },
    [NEO_CONFIG_KEYS.BUKASEND_COD_CONFIG]: {
      mutationName: 'configuration/SET_COD_CONFIG',
    },
    [NEO_CONFIG_KEYS.BUKASEND_BENEFITS_CONFIG]: {
      mutationName: 'configuration/SET_BENEFITS_CONFIG',
    },
  };

  if (path.indexOf('/bukasend/kurir/') >= 0) {
    const courierCode = path.split('?')[0].split('/')[3];
    if (courierCode) {
      const courierLandingNeoId = NEO_CONFIG_KEYS.BUKASEND_COURIER_LANDING_CONFIG.replace(
        '%COURIER_CODE%',
        courierCode,
      );
      mappingConfigsData[courierLandingNeoId] = {
        mutationName: 'configuration/SET_COURIER_LANDING_CONFIG',
      };
    }
  }

  neoConfigs.configs.forEach(neoConfig => {
    const { id, data } = neoConfig;

    processingResponse(app, id, data, null, mappingConfigsData);
  });

  const mappingConfigsAdditional = {
    setCleanupCTAExperiment: {
      actionName: 'experiment/setCleanupCTAExperiment',
      hook: (data, meta) => ({
        apiResult: {
          data,
          meta: meta || {},
        },
      }),
      callback: (data, meta) => {
        const httpStatus = curry(['http_status'], meta);

        app.$store.commit('experiment/SET_FETCH_EXPERIMENT_STATUS', {
          key: 'BEE-7212_ALL_Bukasend_Cleanup_CTA',
          status: httpStatus === 200,
        });
      },
    },
    setRolePermission: {
      mutationName: 'configuration/SET_ROLE_PERMISSION',
    },
    setVoucherSuggestions: {
      mutationName: 'configuration/SET_VOUCHER_SUGGESTIONS',
    },
    setMemberships: {
      mutationName: 'configuration/SET_MEMBERSHIPS',
    },
    setKyc: {
      mutationName: 'configuration/SET_KYC_CONFIG',
    },
    fetchConfiguration: {
      mutationName: 'configuration/SET_CONFIGURATION',
      callback: data => {
        app.$store.commit('configuration/SET_FETCH_CONFIG_STATUS', !!data);
      },
    },
    setOnboardings: {
      mutationName: 'configuration/SET_ONBOARDINGS',
    },
    setRegisteredCouriers: {
      mutationName: 'courier/SET_REGISTERED_COURIERS',
      hook: data => data || [],
    },
    setCourierTags: {
      mutationName: 'configuration/SET_COURIER_TAGS',
    },
  };
  neoConfigs.additional.forEach(({ key, payload, meta }) => {
    processingResponse(app, key, payload, meta, mappingConfigsAdditional);
  });

  const mappingOtherRequests = {
    fetchBackendOpsTimeCouriers: {
      mutationName: 'courier/SET_BACKEND_COURIER_OPS_TIME_CONFIG',
    },
  };

  Object.keys(otherRequests || {}).forEach(key => {
    const { data, meta } = otherRequests[key];

    processingResponse(app, key, data, meta, mappingOtherRequests);
  });
}

module.exports = {
  initStoreData,
};

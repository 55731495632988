import cloneDeep from 'lodash.clonedeep';
import { TRANSACTIONS_FILTERS } from 'shared_js/constant/bukasend';

export default {
  APPEND_TRANSACTIONS(state, transactionIds) {
    const { [state.activeStatus]: transactions } = state.transactions;
    state.transactions[state.activeStatus] = transactions.concat(transactionIds);
  },
  SET_DETAIL_TRANSACTIONS(state, transactions) {
    state.detailTransactions = {
      ...state.detailTransactions,
      ...transactions,
    };
  },
  SET_FETCH_TRANSACTIONS_STATUS(state, status) {
    state.fetchTransactionsStatus[state.activeStatus] = status;
  },
  SET_INVOICES(state, invoices) {
    state.invoices = [...state.invoices, ...invoices];
  },
  SET_DRAFTS(state, drafts) {
    state.drafts = [...state.drafts, ...drafts];
  },
  RESET_DRAFTS(state) {
    state.drafts = [];
    state.perPageTransactions.draft = {};
    state.fetchTransactionsStatus.draft = '';
  },
  SET_PER_PAGE_TRANSACTIONS(state, { page, transactions }) {
    const { [state.activeStatus]: perPageTransactions } = state.perPageTransactions;
    state.perPageTransactions[state.activeStatus] = {
      ...perPageTransactions,
      [page]: transactions,
    };
  },
  SET_CURRENT_PAGE(state, page) {
    state.currentPage[state.activeStatus] = page;
  },
  SET_SEARCH_KEYWORD(state, { activeStatus, keyword }) {
    const status = activeStatus || state.activeStatus;
    state.searchKeyword[status] = keyword;
  },
  SET_TOTAL_PAGE(state, total) {
    state.total[state.activeStatus] = total;
  },
  RESET_TRANSACTIONS(state, activeStatus) {
    const status = activeStatus || state.activeStatus;
    state.transactions[status] = [];
    state.total[status] = null;
    state.fetchTransactionsStatus[status] = '';
    state.activeTrxFilter[status] = TRANSACTIONS_FILTERS.BUKASEND;
  },
  RESET_PER_PAGE_TRANSACTIONS(state, activeStatus) {
    const status = activeStatus || state.activeStatus;
    state.currentPage[status] = 1;
    state.perPageTransactions[status] = {};
    state.total[status] = null;
  },
  SET_DETAIL_TRANSACTION_HISTORY(state, { id, histories }) {
    const detail = cloneDeep(state.detailTransactions[id]);
    detail.histories = histories;

    state.detailTransactions[id] = detail;
  },
  SET_TRX_ACTIVE_STATUS(state, status) {
    state.activeStatus = status;
  },
  SET_ACTIVE_TRX_FILTER(state, filter) {
    state.activeTrxFilter[state.activeStatus] = filter;
  },
  SET_ACTIVE_TRX_QUERY(state, query) {
    state.activeTrxQuery[state.activeStatus] = query;
  },
  UPDATE_DETAIL_TRANSACTION(state, transaction) {
    const detail = cloneDeep(transaction);
    state.detailTransactions[detail.id] = detail;
  },
  TOGGLE_TRANSACTION_MODALS(state, { modalName, status }) {
    state.modals[modalName] = status;
  },
  SET_IS_NEW_USER(state, isNewUser) {
    state.isNewUser = isNewUser;
  },
  DELETE_FROM_TRANSACTIONS(state, { status, id }) {
    const index = state.transactions[status].findIndex(trxId => parseInt(trxId, 10) === parseInt(id, 10));
    if (index >= 0) {
      state.transactions[status].splice(index, 1);
    }
  },
  SET_TRANSACTION_SUMMARY(state, data) {
    state.transactionSummary = data;
  },
  SET_TRANSACTION_SUMMARY_HASH(state, data) {
    state.transactionSummaryHash[data.period] = data;
  },
  SET_TRANSACTION_RECAPS(state, data) {
    state.transactionRecaps = data;
  },
  SET_FETCH_TRANSACTION_SUMMARY_STATUS(state, status) {
    state.fetchTransactionSummaryStatus = status;
  },
  SET_FETCH_TRANSACTION_SUMMARY_ERROR(state, error) {
    state.fetchTransactionSummaryError = error;
  },
  SET_FETCH_TRANSACTION_RECAPS_STATUS(state, status) {
    state.fetchTransactionRecapsStatus = status;
  },
  SET_PAGINATION_LIMIT(state, { activeStatus, limit }) {
    const status = activeStatus || state.activeStatus;
    state.paginationLimit[status] = limit;
  },
  SET_TRANSACTION_FILTERS(state, { activeStatus, key, value }) {
    const status = activeStatus || state.activeStatus;
    state.transactionFilters[status][key] = value;
  },
};

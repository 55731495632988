import { API_BASE_URL } from 'shared_js/constant/bukasend';

const apiPath = '/open-shipments/address-books';
const url = `${API_BASE_URL}${apiPath}`;
const aggregateURL = `${API_BASE_URL}/aggregate`;

const interfaces = {
  getAddresses(params = {}) {
    return this.get(url, 'public', { params });
  },
  getAddressesByIds(ids) {
    const aggregate = ids.reduce(
      (res, id) => ({
        ...res,
        [id]: {
          method: 'GET',
          path: `${apiPath}/${id}`,
        },
      }),
      {},
    );
    return this.post(aggregateURL, 'public', {
      data: { aggregate },
    });
  },
  createAddress(data) {
    return this.post(`${url}`, 'public', { data });
  },
  updateAddress(data) {
    return this.patch(`${url}/${data.id}`, 'public', { data });
  },
  deleteAddress(id) {
    return this.delete(`${url}/${id}`, 'public');
  },
};

export default interfaces;

import { API_BASE_URL } from 'shared_js/constant/bukasend';

const url = `${API_BASE_URL}/open-shipments/extensions/register`;

const interfaces = {
  fetchRegisteredAccount() {
    return this.get(url, 'public');
  },
  unregisterAccount(params) {
    return this.delete(url, 'public', { params });
  },
};

export default interfaces;

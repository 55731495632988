<template>
  <div>
    <transition name="slide">
      <bl-flex-container-mv
        v-if="isOpened"
        align-items="center"
        class="pv-12 ph-16 floating-a2hs"
        data-test-a2hs
      >
        <bl-flex-item-av>
          <div data-test-a2hs-close @click="closePrompt">
            <BlIconAv name="ico_cross_minor" />
          </div>
        </bl-flex-item-av> 
        <bl-flex-item-av class="mr-12 ml-16">
          <img :src="getAssetUrl('images/bukasend-icon.png')">
        </bl-flex-item-av>  
        <bl-flex-item-av :shrink="1" :grow="1">
          <bl-text-av variation="caption-12" data-test-a2hs-label>
            Akses BukaSend makin satset lewat shortcut di Hp.
          </bl-text-av>
        </bl-flex-item-av>
        <bl-flex-item-av>
          <bl-button-av
            size="small"
            class="ml-12"
            data-test-a2hs-add
            @click="showChromePrompt"
          >
            Mau!
          </bl-button-av>
        </bl-flex-item-av>
      </bl-flex-container-mv>
    </transition>
    <bl-sheet-mv
      :active.sync="showModal"
      auto-height
      title="Shortcut BukaSend"
      class="bl-sheet--no-handler"
      data-test-bl-sheet
    >
      <div slot="content" class="u-pad--4 u-txt--center">
        <bl-illustration-av
          name="img_medium_app_bukalapak"
          alt="Shortcut BukaSend"
          width="200px"
          class="u-mrgn-bottom--4"
        />
        <bl-text-av class="u-mrgn-bottom--2" variation="subheading-18">
          Install BukaSend di HP-mu
        </bl-text-av>
        <bl-text-av class="u-mrgn-bottom--7">
          Akses BukaSend makin satset lewat shortcut di HP
        </bl-text-av>
        <bl-button-av
          variation="primary"
          fullwidth
          @click="showChromePrompt"
        >
          Tambah Shortcut
        </bl-button-av>
      </div>
    </bl-sheet-mv>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { BlIconAv } from '@bukalapak/bazaar-visual';
import {
  BlFlexContainerMv,
  BlFlexItemAv,
  BlButtonAv,
  BlTextAv,
  BlSheetMv,
} from '@bukalapak/bazaar-mweb';
import BlIllustrationAv from '@bukalapak/bazaar-visual-1.13.0/dist/components/BlIllustrationAv';  
import AssetsMixin from 'shared_js/mixins/assets';
import TrackerMixin from 'shared_js/mixins/new-tracker';
import UserAgentMixin from 'shared_js/mixins/user-agent';
import curry from 'shared_js/helpers/curry-helper';

/**
 * MIXINS
 * AssetMixin: getAssetUrl
 * TrackerMixin: trackVisitScreen, trackClickEvent
 */

export default {
  components: {
    BlIconAv,
    BlFlexContainerMv,
    BlFlexItemAv,
    BlButtonAv,
    BlTextAv,
    BlSheetMv,
    BlIllustrationAv,
  },
  mixins: [AssetsMixin, TrackerMixin, UserAgentMixin],
  data() {
    return {
      isOpened: false,
      redirectToInstallPWA: false,
      showModal: false,
    };
  },
  computed: {
    ...mapState('configuration', ['miscConfig']),
  },
  watch: {
    showModal(isShow) {
      if (!isShow) {
        this.closePrompt();
      }
    }
  },
  mounted() {
    const isPWA =  window.matchMedia('(display-mode: standalone)').matches;
    if (isPWA) {
      this.trackVisitScreen({
        current_screen: this.$route.name,
        screen_version: 'pwa_page',
      });
    } else {
      this.initListener();
    }
  },
  methods: {
    initListener() {
      const isRouteValid = !['print_resi_page', 'bulk_print_resi_page', 'print_invoice_page', 'mado_print_resi_page'].includes(this.$route.name);
      const supportInstallPrompt = window.onbeforeinstallprompt === null;
      const { isChrome } = global.USER.agent;

      if (isRouteValid) {
        if (supportInstallPrompt && isChrome) {
          window.addEventListener('beforeinstallprompt', this.deferPrompt);
        } else if ((this.isBLAndroid || this.isBLMitra) && this.isVersionValid()) {
          this.isOpened = true;
          this.redirectToInstallPWA = true;
        }
      }
    },
    deferPrompt(e) {
      e.preventDefault();
      const deferredPrompt = e;
      window.DEFERRED_PROMPT = deferredPrompt;

      this.isOpened = !!deferredPrompt;
      window.removeEventListener('beforeinstallprompt', this.deferPrompt);

      if (!this.isDesktop && parseInt(this.$route.query.install_bukasend_pwa, 10) === 1) {
        this.isOpened = false;
        this.showModal = true;
      }
    },
    showChromePrompt() {
      this.trackClickEvent({
        current_screen: this.$route.name,
        action: 'click_pwa_want',
      });
      this.isOpened = false;
      this.showModal = false;
      if (this.redirectToInstallPWA) {
        const url = new URL(window.location.href);
        url.searchParams.set('install_bukasend_pwa', 1);

        window.location.href = url.href;
      } else {
        const deferredPrompt = window.DEFERRED_PROMPT;
        deferredPrompt.prompt();
        deferredPrompt.userChoice.then(choice => {
          window.DEFERRED_PROMPT = null;
          const action = choice.outcome === 'accepted' ? 'click_accept_pwa' : 'click_decline_pwa';
          this.trackClickEvent({
            current_screen: this.$route.name,
            action,
          });
        });
      }
    },
    closePrompt() {
      this.isOpened = false;
      this.trackClickEvent({
        current_screen: this.$route.name,
        action: 'click_close_pwa',
      });
    },
    isVersionValid() {
      const appVersions = {
        android: this.BLAndroidVersion(),
        mitra: this.BLMitraVersion(),
      };
      const currentVersion = appVersions[this.platform];
      const minVersion = curry(['addToHomeScreen', 'min_support', this.platform], this.miscConfig);

      return Boolean(currentVersion && minVersion && currentVersion >= minVersion);
    },
  },
};
</script>
<style lang="scss" scoped>
.floating-a2hs {
  position: fixed;
  z-index: 99;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  width: 100%;
  max-width: 480px;
}

.slide-enter-active,
.slide-leave-active {
  transition: 0.5s;
}
.slide-enter,
.slide-leave-to {
  transform: translateX(-50%) translateY(100%);
  opacity: 0;
  z-index: 0;
}
</style>

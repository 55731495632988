import curry from 'shared_js/helpers/curry-helper';
import { Loader } from '@googlemaps/js-api-loader';
import { IS_SSR } from 'shared_js/constant/general';

export default {
  data() {
    return {
      googleMapLoaded: false,
      geocoder: null,
    };
  },
  methods: {
    onGoogleMapLoad() {
      this.$bus.$on('GOOGLE_MAP_LOAD', async payload => {
        if (!this.googleMapLoaded) {
          this.googleFontDisable();

          const loader = new Loader({
            libraries: ['places'],
            apiKey: process.env.GOOGLE_MAP_API_KEY,
          });

          await loader.load().then(() => {
            this.googleMapLoaded = true;
          });
        }

        this.googleMapCallback(payload);
      });
    },
    googleMapCallback(payload) {
      if (payload && typeof payload.callback === 'function') {
        payload.callback();
      }
    },
    googleFontDisable() {
      if (IS_SSR) {
        return;
      }
      const head = document.getElementsByTagName('head')[0];
      const { insertBefore } = head;

      head.insertBefore = function(newElement, referenceElement) {
        if (newElement.href && newElement.href.indexOf('//fonts.googleapis.com/css?family=Roboto') > -1) {
          return;
        }
        insertBefore.call(head, newElement, referenceElement);
      };
    },
    initGmapAutocompleteResults(status, results) {
      const autocompleteResult = [];
      if (status === global.google.maps.places.PlacesServiceStatus.OK) {
        const MAX_LENGTH_FOR_SHOW_DEFAULT_TEXT = 4;
        results.forEach((item, idx) => {
          const terms = Object.assign([], item.terms);
          autocompleteResult.push(item);
          if (item.types.includes('postal_code') || terms.length <= MAX_LENGTH_FOR_SHOW_DEFAULT_TEXT) {
            const secondaryText = curry(['structured_formatting', 'secondary_text'], item);
            if (secondaryText) {
              autocompleteResult[idx].custom_subtitle = secondaryText.replace(', Indonesia', '');
            } else {
              autocompleteResult.pop();
            }
          } else {
            autocompleteResult[idx].custom_subtitle = [
              ...terms.splice(1, 1), // get index === 1, then remove index 1 from `terms`
              ...terms.slice(1, -1).slice(-3), // remove first index and last index, get last 3 item after first index and last index is removed.
            ]
              .map(({ value }) => value)
              .join(', '); // join index 1 and last 3 index.
          }
        });
      }
      return autocompleteResult;
    },
    getGeocoder() {
      return new global.google.maps.Geocoder();
    },
    async reverseGoogleGeocode({ latitude, longitude, placeId }) {
      if (!this.geocoder) {
        this.geocoder = this.getGeocoder();
      }

      let result = null;

      const parameter = {};

      if (latitude && longitude) {
        parameter.location = {
          lat: latitude,
          lng: longitude,
        };
      } else {
        parameter.placeId = placeId;
      }

      await new Promise(resolve => {
        this.$emit('fetchDataStatusChanged', null);
        this.geocoder.geocode(parameter, (results, status) => {
          result = {
            results,
            status,
          };
          if (status === 'OK' && results[0]) {
            this.$emit('fetchDataStatusChanged', true);
          } else {
            this.$emit('fetchDataStatusChanged', false);
          }
          resolve(true);
        });
      });
      return result;
    },
  },
};

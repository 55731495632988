import { API_BASE_URL } from 'shared_js/constant/bukasend';

const url = `${API_BASE_URL}/open-shipments/drafts`;
const aggregateURL = `${API_BASE_URL}/aggregate`;

const interfaces = {
  getDrafts(params) {
    return this.get(url, 'public', { params });
  },
  getDraftsByIds(ids) {
    const aggregate = ids.reduce(
      (res, id) => ({
        ...res,
        [id]: {
          method: 'GET',
          path: `/open-shipments/drafts/${id}`,
        },
      }),
      {},
    );
    return this.post(aggregateURL, 'public', {
      data: { aggregate },
    });
  },
  bulkDeleteDrafts(ids) {
    const aggregate = ids.reduce(
      (res, id) => ({
        ...res,
        [id]: {
          method: 'DELETE',
          path: `/open-shipments/drafts/${id}`,
        },
      }),
      {},
    );
    return this.post(aggregateURL, 'public', {
      data: { aggregate },
    });
  },
  updateDraft(id, data) {
    return this.patch(`${url}/${id}`, 'public', { data });
  },
};

export default interfaces;

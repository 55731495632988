import { mapState } from 'vuex';
import { WEIGHT_UNITS } from 'shared_js/constant/bukasend';
import { IS_SSR } from 'shared_js/constant/general';

export default {
  data() {
    return {
      directAccess: 'direct-access',
    };
  },
  computed: {
    ...mapState('configuration', ['sessionId', 'entryPoint']),
  },
  methods: {
    getReferrer() {
      const routerReferrerPath = this.$router.referrer ? this.$router.referrer.href : '';
      const routerReferrer = routerReferrerPath && !IS_SSR ? `${window.location.origin}${routerReferrerPath}` : '';

      return routerReferrer || (IS_SSR ? '' : document.referrer) || this.directAccess;
    },
    getParamsVisitTracker(currentCashback) {
      return {
        session_id: this.sessionId,
        entry_point: this.entryPoint || this.directAccess,
        cashback_type: currentCashback.type,
        referrer: this.getReferrer(),
      };
    },
    trackVisitLandingPage(currentCashback) {
      this.$tracker.trackEvent('visit_landing_page', this.getParamsVisitTracker(currentCashback));
    },
    trackLandingPageClickVoucher() {
      this.$tracker.trackEvent('landing_page_click_voucher', {
        session_id: this.sessionId,
      });
    },
    trackLandingPageClickKirimPaketSatuan() {
      this.$tracker.trackEvent('landing_page_click_kirim_paket_satuan', {
        session_id: this.sessionId,
      });
    },
    trackLandingPageClickKirimPaket() {
      this.$tracker.trackEvent('landing_page_click_kirim_paket', {
        session_id: this.sessionId,
      });
    },
    trackLandingPageClickHistoryTransaction() {
      this.$tracker.trackEvent('landing_page_click_history_transaction', {
        session_id: this.sessionId,
      });
    },
    trackLandingPageClickCekOngkir() {
      this.$tracker.trackEvent('landing_page_click_cek_ongkir', {
        session_id: this.sessionId,
      });
    },
    trackLandingPageClickCekMurahnyaDisini() {
      this.$tracker.trackEvent('landing_page_click_cek_murahnya_disini', {
        session_id: this.sessionId,
      });
    },
    trackLandingPageClickKirimPaketSekaligus() {
      this.$tracker.trackEvent('landing_page_click_kirim_paket_sekaligus', {
        session_id: this.sessionId,
      });
    },
    trackVisitBookingFormPage(currentCashback) {
      this.$tracker.trackEvent('visit_booking_form_page', this.getParamsVisitTracker(currentCashback));
    },
    trackBookingFormClickLihatPengiriman(currentCashback, transaction) {
      const { type, amount } = currentCashback;
      const {
        weight,
        weight_unit: weightUnit,
        volumetric,
        notes,
        from,
        to,
        courier,
        dropship,
        saveContact = false,
      } = transaction;
      const weightUnitParam = weightUnit === WEIGHT_UNITS.GR.value ? 'gram' : 'kilogram';

      const params = {
        session_id: this.sessionId,
        cashback_type: type,
        cashback_amount: amount,
        item_weight_gr: weight,
        weight_type: volumetric ? 'volumetrik' : weightUnitParam,
        detail_package: notes,
        city_origin: from.city,
        city_destination: to.city,
        courier: courier.service_name || '',
        courier_cost: courier.price || 0,
        courier_service_type: courier.courier_service_type || '',
        is_dropship: dropship,
        save_contact: saveContact,
      };

      this.$tracker.trackEvent('booking_form_click_lihat_pengiriman', params);
    },
    trackVisitSummaryPage(currentCashback) {
      this.$tracker.trackEvent('visit_summary_page', this.getParamsVisitTracker(currentCashback));
    },
    trackDesktopVisitBookingFormPage(currentCashback) {
      this.$tracker.trackEvent('desktop_visit_booking_form_page', this.getParamsVisitTracker(currentCashback));
    },
    trackBukasendLeavePage(pageName, visitDuration) {
      this.$tracker.trackEvent('bukasend_leave_page', {
        session_id: this.sessionId,
        page_name: pageName,
        visit_duration: visitDuration,
      });
    },
    trackInsuranceShownSummaryPage(defaultOptType = true) {
      this.$tracker.trackEvent('asuransi_pengiriman_bukasend_shown', {
        session_id: this.sessionId,
        default_opt_type: defaultOptType,
      });
    },
    trackVisitInsuranceBenefits() {
      this.$tracker.trackEvent('asuransi_pengiriman_bukasend_lihat_manfaat', {
        session_id: this.sessionId,
      });
    },
    trackVisitInsuranceTermsAndConditions() {
      this.$tracker.trackEvent('asuransi_pengiriman_bukasend_snk', {
        session_id: this.sessionId,
      });
    },
    trackAddDeliveryPackage(optTypeValue) {
      this.$tracker.trackEvent('asuransi_pengiriman_bukasend_tambah_paket', {
        session_id: this.sessionId,
        opt_type_value: optTypeValue,
      });
    },
    trackVisitDeliveryList() {
      this.$tracker.trackEvent('asuransi_pengiriman_bukasend_daftar_pengiriman', {
        session_id: this.sessionId,
      });
    },
    trackContinueToPayment(packageCount, insurance) {
      this.$tracker.trackEvent('asuransi_pengiriman_bukasend_pembayaran', {
        session_id: this.sessionId,
        package_count: packageCount,
        insurance,
      });
    },
    trackVisitTransactionHistoryInsurance() {
      this.$tracker.trackEvent('asuransi_pengiriman_bukasend_histori', {
        session_id: this.sessionId,
      });
    },
    trackLandingPageClickChangeLog() {
      this.$tracker.trackEvent('bukasend_landing_page_click_changelog', {
        session_id: this.sessionId,
      });
    },
    trackClickLatePickupSection(transaction) {
      const { delivery, transaction_id: transactionId, pickup_time: pickupTime } = transaction;
      const {
        courier,
        from,
        consignee,
        booking_code: bookingCode,
        courier_service_type: courierServiceType,
      } = delivery;

      this.$tracker.trackEvent('bukasend_click_late_pickup_section', {
        courier,
        session_id: this.sessionId,
        transaction_id: transactionId,
        booking_code: bookingCode,
        courier_service_type: courierServiceType,
        enable_pickup_time: !!pickupTime,
        city_origin: from.city,
        city_destination: consignee.city,
      });
    },
    trackLatePickupPageClickChat() {
      this.$tracker.trackEvent('bukasend_late_pickup_page_click_chat', {
        session_id: this.sessionId,
      });
    },
    trackLatePickupPageClickDropOff() {
      this.$tracker.trackEvent('bukasend_late_pickup_page_click_drop_off', {
        session_id: this.sessionId,
      });
    },
    trackLatePickupPageClickCallCs() {
      this.$tracker.trackEvent('bukasend_late_pickup_page_click_call_cs', {
        session_id: this.sessionId,
      });
    },
    trackLandingPageClickChatCS() {
      this.$tracker.trackEvent('bukasend_landing_page_click_chat_cs', {
        session_id: this.sessionId,
      });
    },
    trackBukasendLandingPageImpressTestimonyCard(cardPosition) {
      this.$tracker.trackEvent('bukasend_landing_page_impress_testimony_card', {
        session_id: this.sessionId,
        card_position: cardPosition,
      });
    },
  },
};

import { isLoggedIn, getUserId } from 'shared_js/helpers/user-helper';
import { MEMBERSHIP_TIERS, BUDGET_PROMO_TYPES } from 'shared_js/constant/bukasend';
import curry from 'shared_js/helpers/curry-helper';
import { isBudgetPromoActive } from 'shared_js/helpers/promo-budget-helper';

const ONGOING_STATES = ['paid', 'accepted', 'processed', 'delivered'];
const DONE_STATES = ['received', 'remitted', 'cancelled', 'refunded', 'expired', 'lost'];
const CASHBACK_STATES = ['received', 'not_received'];
const CASHBACK_3PL_BENEFIT_TYPES = [
  'cashback_pickup',
  'cashback_dropoff',
  'cashback_cod_pickup',
  'cashback_cod_dropoff',
];
const CASHBACK_ONGKIR_GOKIL_BENEFIT_TYPES = [
  'cashback_ongkir_gokil_pickup',
  'cashback_ongkir_gokil_dropoff',
  'cashback_cod_ongkir_gokil_pickup',
  'cashback_cod_ongkir_gokil_dropoff',
];

const mappingStates = (data, states) =>
  Object.keys(data || {}).reduce((result, state) => {
    if (states.includes(state)) {
      // eslint-disable-next-line no-param-reassign
      result = result.concat(data[state].filter(stateName => !result.includes(stateName)));
    }
    return result;
  }, []);

const getHighestCashback = ({ isOngkirGokil = false, currentMembershipBenefits }) => {
  let promotionEndAt = null;
  const benefitTypes = isOngkirGokil ? CASHBACK_ONGKIR_GOKIL_BENEFIT_TYPES : CASHBACK_3PL_BENEFIT_TYPES;
  const highestCashback = benefitTypes.reduce((result, benefitKey) => {
    Object.keys(currentMembershipBenefits).forEach(type => {
      currentMembershipBenefits[type].forEach(benefit => {
        const { data, end_at: endAt } = benefit;
        const { active, value } = data[benefitKey] || {};
        if (active && value && value > result) {
          promotionEndAt = endAt;
          // eslint-disable-next-line no-param-reassign
          result = value;
        }
      });
    });
    return result;
  }, 0);

  return {
    promotionEndAt,
    highestCashback,
  };
};

export default {
  userIsBlacklist(state) {
    return !!curry(['configuration', 'blacklisted'], state);
  },
  deviceIsBlacklist(state) {
    return !!curry(['configuration', 'device_blacklisted'], state);
  },
  userIsCustomFieldWhitelist(state) {
    return !!(state.customFieldWhitelistUsers || []).find(id => parseInt(id, 10) === parseInt(getUserId(), 10));
  },
  alreadySeeOnboarding(state) {
    const { onboarding_flag: onboardingFlag } = state.onboardings || {};

    return !!onboardingFlag;
  },
  alreadySeeCoachmark(state) {
    const { coachmark_flag: coachmarkFlag } = state.onboardings || {};

    return !!coachmarkFlag;
  },
  alreadySeeUSP(state) {
    const { usp_flag: flag } = state.onboardings || {};

    return !!flag;
  },
  miscChangeLogUrl(state) {
    const { changeLog = {} } = state.miscConfig || {};

    return changeLog.url || '';
  },
  isRebookOrResendEnabled(state) {
    const { rebookEnabled = false, resendEnabled = false } = state.rebookConfig || {};
    return rebookEnabled || resendEnabled;
  },
  currentMembership(state) {
    const tierName = isLoggedIn() ? curry(['membership_tier'], state.configuration) : MEMBERSHIP_TIERS.gold;
    return (state.memberships || []).find(item => item.tier_name === tierName);
  },
  currentMembershipBenefits(state, getters) {
    const { pickup, dropoff, cod } = state.membershipBenefits;
    const { tier_name: tierName } = getters.currentMembership;

    return {
      pickup: pickup[tierName] || [],
      dropoff: dropoff[tierName] || [],
      cod: cod[tierName] || [],
    };
  },
  highestCashback3PL(_, getters) {
    return getHighestCashback({ isOngkirGokil: false, currentMembershipBenefits: getters.currentMembershipBenefits });
  },
  highestCashbackOngkirGokil(_, getters) {
    return getHighestCashback({ isOngkirGokil: true, currentMembershipBenefits: getters.currentMembershipBenefits });
  },
  codBenefitUsage(state) {
    const benefitUsages = curry(['user_benefit_usage'], state.configuration) || [];
    return benefitUsages.find(usage => usage.benefit_type === 'cod');
  },
  membershipBudgetActive(_, __, { promoBudget, toggle }) {
    return isBudgetPromoActive(
      BUDGET_PROMO_TYPES.MEMBERSHIPS,
      promoBudget.promoBudgetInfo,
      toggle.togglePromotionBudgetEnabled,
    );
  },
  ongkirGokilBudgetActive(_, __, { promoBudget, toggle }) {
    return isBudgetPromoActive(
      BUDGET_PROMO_TYPES.ONGKIR_GOKIL,
      promoBudget.promoBudgetInfo,
      toggle.togglePromotionBudgetEnabled,
    );
  },
  isRolePartner({ rolePermission }, _, { toggle }) {
    const partnerRoleEnabled = curry(['togglePartnerRoleEnabled'], toggle);
    const roleName = curry(['name'], rolePermission);
    return partnerRoleEnabled && roleName === 'partner';
  },
  hasPartnerSecret(state) {
    return !!(state.partnerSecret && state.partnerSecret.id);
  },
  filteredMemberships(state, _, { toggle }) {
    const toggleMembershipRuleEnabled = curry(['toggleMembershipRuleEnabled'], toggle);
    const bukasendKycSubmitted = Boolean(curry(['bukasend_kyc_submitted'], state.kyc));
    const isKYCVerified = Boolean(curry(['state'], state.kyc) === 'verified') && bukasendKycSubmitted;
    const roleName = curry(['name'], state.rolePermission);

    return state.memberships.filter(membership => {
      if (membership.tier_name === MEMBERSHIP_TIERS.nonmember) {
        return false;
      }
      const selectedMembershipConfig =
        curry(['membership', 'tiers', membership.tier_name, 'show'], state.miscConfig) || {};
      const requireKyc = selectedMembershipConfig.require_kyc || false;
      const eligibleRoles = selectedMembershipConfig.eligible_roles || ['regular', 'agent'];

      if (toggleMembershipRuleEnabled && (!eligibleRoles.includes(roleName) || (requireKyc && !isKYCVerified))) {
        return false;
      }

      return true;
    });
  },
  lowestTierSupportBulkUpload(state) {
    return state.memberships.find(membership => Boolean(curry(['benefits', 'bulk_upload', 'active'], membership)));
  },
  ongoingStates(state) {
    return mappingStates(curry(['shipping_states'], state.information), ONGOING_STATES);
  },
  completedStates(state) {
    return mappingStates(curry(['shipping_states'], state.information), DONE_STATES);
  },
  cashbackStates(state) {
    return mappingStates(curry(['cashback_states'], state.information), CASHBACK_STATES);
  },
  courierTags(state) {
    const tags = state.courierTags;
    if (!tags) {
      return [];
    }
    return tags.courier_tags.filter(item => item.active === true) || [];
  },
};

import { isLoggedIn } from 'shared_js/helpers/user-helper';

const VARIANT = 'variant';

export default {
  bukasendCleanupCTAExperimentKey({ experimentKeys }) {
    return experimentKeys.BUKASEND_CLEANUP_CTA;
  },
  bukasendCleanupCTAExperimentStatus({ fetchExperimentStatus, experimentKeys }) {
    return fetchExperimentStatus[experimentKeys.BUKASEND_CLEANUP_CTA];
  },
  isCleanupCTAGetVariant({ experimentKeys, experiments }) {
    return experiments[experimentKeys.BUKASEND_CLEANUP_CTA] === VARIANT;
  },
  isNewLandingPageActive(_, __, { transactions }) {
    const { isNewUser } = transactions || {};
    return !isLoggedIn() || isNewUser;
  },
  homeVoucherBannerExperimentKey({ experimentKeys }) {
    return experimentKeys.HOME_VOUCHER_BANNER;
  },
  homeVoucherBannerExperimentStatus({ fetchExperimentStatus }, { homeVoucherBannerExperimentKey }) {
    return fetchExperimentStatus[homeVoucherBannerExperimentKey];
  },
  homeVoucherBannerExperiment({ experiments }, { homeVoucherBannerExperimentKey }) {
    return experiments[homeVoucherBannerExperimentKey];
  },
  isHomeVoucherBannerActive(_, { homeVoucherBannerExperiment }, { toggle }) {
    const { toggleHomeVoucherBannerEnabled = false } = toggle || {};
    const isVariant = homeVoucherBannerExperiment === VARIANT;
    return toggleHomeVoucherBannerEnabled && isVariant;
  },
  bukasendCostCheckParentPageExperimentKey({ experimentKeys }) {
    return experimentKeys.COST_CHECK_PARENT_PAGE;
  },
  bukasendCostCheckParentPageExperimentStatus({ fetchExperimentStatus, experimentKeys }) {
    return fetchExperimentStatus[experimentKeys.COST_CHECK_PARENT_PAGE];
  },
  bukasendCostCheckParentPage({ experiments, experimentKeys }) {
    return experiments[experimentKeys.COST_CHECK_PARENT_PAGE];
  },
  isCostCheckParentPageGetVariant(_, { bukasendCostCheckParentPage }) {
    return bukasendCostCheckParentPage === VARIANT;
  },
};

import configurationApis from 'shared_js/api/public/configurations';
import bannersApis from 'shared_js/api/public/banners';
import cloneDeep from 'lodash.clonedeep';

export default {
  async fetchConfiguration({ commit, state }, params = {}) {
    const { forceReload } = params;
    if (!forceReload && state.configuration !== null) {
      return;
    }

    commit('SET_FETCH_CONFIG_STATUS', null);

    try {
      const response = params.apiResult || (await configurationApis.getConfiguration());
      let status = false;

      if (response.meta.http_status === 200) {
        status = true;
        const { data } = response;

        commit('SET_CONFIGURATION', data);
      }
      commit('SET_FETCH_CONFIG_STATUS', status);
    } catch (e) {
      commit('SET_FETCH_CONFIG_STATUS', false);
    }
  },

  async fetchInformation({ commit, state }, params = {}) {
    if (state.information !== null) {
      return;
    }

    commit('SET_FETCH_INFORMATION_STATUS', null);

    try {
      const response = params.apiResult || (await configurationApis.getInformation());
      let status = false;

      if (response.meta.http_status === 200) {
        status = true;
        const { data } = response;

        commit('SET_INFORMATION', data);
      }
      commit('SET_FETCH_INFORMATION_STATUS', status);
    } catch (e) {
      commit('SET_FETCH_INFORMATION_STATUS', false);
    }
  },
  async updateOnboarding({ commit }, data) {
    try {
      const response = await configurationApis.updateOnboarding(data);
      commit('SET_ONBOARDINGS', response.data);

      return response;
    } catch (err) {
      throw new Error(err);
    }
  },
  async fetchBannerSliders({ state, commit }) {
    if (state.bannerSliders !== null) {
      return;
    }

    try {
      const { data } = await bannersApis.getBanners({ banner_type: 'banner' });
      commit('SET_STATE_VALUE', { stateName: 'bannerSliders', value: data });
    } catch (err) {
      throw err;
    }
  },
  async fetchMembershipBenefits({ commit, state, getters, rootState }, params = {}) {
    if (!rootState.toggle.toggleMultiplePromotionEnabled) {
      return;
    }

    const membershipBenefits = cloneDeep(state.membershipBenefits);
    const { tier_name: currentTierName } = getters.currentMembership;
    const { tierName, forceReload } = params;

    const targetTierName = tierName || currentTierName;
    if (
      !forceReload &&
      (membershipBenefits.pickup[targetTierName] &&
        membershipBenefits.dropoff[targetTierName] &&
        membershipBenefits.cod[targetTierName])
    ) {
      return;
    }

    /* eslint-disable-next-line */
    for (const type of ['pickup', 'dropoff', 'cod']) {
      try {
        /* eslint-disable-next-line no-await-in-loop */
        const { data } = await configurationApis.getMembershipPromotions({ tier_name: targetTierName, type });
        membershipBenefits[type][targetTierName] = data;
      } catch {
        // do nothing and continue
      }
    }
    commit('SET_STATE_VALUE', {
      stateName: 'membershipBenefits',
      value: membershipBenefits,
    });
  },
};

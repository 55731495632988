export default {
  APPEND_TRANSACTIONS(state, { activeStatus, transactionIds }) {
    const { [activeStatus]: transactions } = state.transactions;
    state.transactions[activeStatus] = transactions.concat(transactionIds);
  },
  SET_DETAIL_TRANSACTIONS(state, transactions) {
    state.detailTransactions = {
      ...state.detailTransactions,
      ...transactions,
    };
  },
  SET_FETCH_TRANSACTIONS_STATUS(state, { activeStatus, status }) {
    state.fetchTransactionsStatus[activeStatus] = status;
  },
  SET_TOTAL_PAGE(state, { activeStatus, total }) {
    state.total[activeStatus] = total;
  },
};

import VueRouter from 'vue-router';
import { isBLIos, isBLAndroid } from 'shared_js/helpers/user-agent-helper';
import { createApp } from './main-app';
import { initialApp, initialAppId, mountPointId } from './app-constant';

const { initStoreData } = require('./store/helpers');

const { Vue, Vuex } = window;

let neoConfigs = {
  configs: [],
  additional: [],
};

let neoToggles = {
  toggles: [],
  additional: [],
};

let otherRequests = {};
let user = null;
let userIdentity = '';
let ssrEnabled = false;
let blDeviceId = '';
let deviceAdId = '';

if (window[initialApp]) {
  const dataString = window[initialApp];

  try {
    ({
      neoToggles,
      neoConfigs,
      otherRequests,
      user,
      userIdentity,
      ssrEnabled,
      blDeviceId = '',
      deviceAdId = '',
    } = JSON.parse(unescape(dataString)));
  } catch (e) {
    /** */
  }
}

let app;
let router;

const createVueApp = () => {
  ({ app, router } = createApp({
    Vue,
    Vuex,
    VueRouter,
  }));
};

const removeInitialData = () => {
  let initScript = document.getElementById(initialAppId);
  if (initScript) {
    initScript.parentElement.removeChild(initScript);
    initScript = null;
    delete global[initialApp];
  }
};

createVueApp();
initStoreData({
  neoToggles,
  neoConfigs,
  otherRequests,
  app,
  path: window.location.pathname,
});

app.fragmentData = {
  user,
  userIdentity,
  ssrEnabled,
};

/* 
  Set device identity to global variable
  IOS is using X-Device-Ad-Id as Identity
  Android is using X-BL-Device-Identity as Identity
  Web/Mweb is using identity cookie as Identity
*/
if (isBLIos()) {
  global.deviceIdentity = deviceAdId;
} else if (isBLAndroid()) {
  global.deviceIdentity = blDeviceId;
} else {
  global.deviceIdentity = userIdentity;
}

removeInitialData();

router.onReady(() => {
  app.$mount(`#${mountPointId}`);
});

import { mapState } from 'vuex';
import { IS_SSR } from 'shared_js/constant/general';
import { isLoggedIn } from 'shared_js/helpers/user-helper';
import curry from 'shared_js/helpers/curry-helper';

const DIRECT_ACCESS = 'direct-access';

export default {
  computed: {
    isLoggedIn,
    ...mapState('configuration', ['sessionId', 'entryPoint', 'kyc']),
  },
  methods: {
    getReferrer() {
      const routerReferrerPath = this.$router.referrer ? this.$router.referrer.href : '';
      const routerReferrer = routerReferrerPath && !IS_SSR ? `${window.location.origin}${routerReferrerPath}` : '';

      return routerReferrer || (IS_SSR ? '' : document.referrer) || DIRECT_ACCESS;
    },
    getGeneralTrackerParams() {
      return {
        session_id: this.sessionId,
        entry_point: this.entryPoint || DIRECT_ACCESS,
        referrer: this.getReferrer(),
        current_screen: this.$route.name,
      };
    },
    generalPropertiesParams() {
      return {
        is_login: this.isLoggedIn,
        is_verified: Boolean(curry(['state'], this.kyc) === 'verified' && curry(['bukasend_kyc_submitted'], this.kyc)),
      };
    },
    trackVisitScreen(params = {}) {
      const trackerParams = {
        ...this.getGeneralTrackerParams(),
        ...params,
        screen_properties: JSON.stringify({
          ...this.generalPropertiesParams(),
          ...params.screen_properties,
        }),
      };
      this.$tracker.trackEvent('bukasend_visit_screen', trackerParams);
    },
    trackClickEvent(params = {}) {
      const trackerParams = {
        ...this.getGeneralTrackerParams(),
        ...params,
        action_properties: JSON.stringify({
          ...this.generalPropertiesParams(),
          ...params.action_properties,
        }),
      };
      this.$tracker.trackEvent('bukasend_click_event', trackerParams);
    },
    trackImpressionEvent(params = {}) {
      const trackerParams = {
        ...this.getGeneralTrackerParams(),
        ...params,
        impression_properties: JSON.stringify({
          ...this.generalPropertiesParams(),
          ...params.impression_properties,
        }),
      };
      this.$tracker.trackEvent('bukasend_impression_event', trackerParams);
    },
    trackClickCheckPrice(params = {}) {
      const trackerParams = { ...this.getGeneralTrackerParams(), ...params };
      this.$tracker.trackEvent('bukasend_click_check_price', trackerParams);
    },
    trackVisitSummaryPageNew(params = {}) {
      const trackerParams = { ...this.getGeneralTrackerParams(), ...params };
      this.$tracker.trackEvent('bukasend_visit_summary_page', trackerParams);
    },
    trackClickContinueToPay(params = {}) {
      const trackerParams = { ...this.getGeneralTrackerParams(), ...params };
      this.$tracker.trackEvent('bukasend_click_continue_to_pay', trackerParams);
    },
    trackClickChosenCourier(params = {}) {
      const trackerParams = { ...this.getGeneralTrackerParams(), ...params };
      this.$tracker.trackEvent('bukasend_click_chosen_courier', trackerParams);
    },
    trackImpressCourierList(params = {}) {
      const trackerParams = { ...this.getGeneralTrackerParams(), ...params };
      this.$tracker.trackEvent('bukasend_impression_courier_list', trackerParams);
    },
  },
};

export default {
  SET_PROFILE(state, data) {
    state.profile = data;
  },
  SET_FETCH_STATUS(state, status) {
    state.fetchStatus = status;
  },
  SET_MITRA_AGENT(state, data) {
    state.mitraAgent = data;
  },
  SET_FETCH_MITRA_AGENT_STATUS(state, status) {
    state.fetchMitraAgentStatus = status;
  },
  SET_BUKASEND_MITRA_AGENT(state, data) {
    state.bukasendMitraAgent = data;
  },
  SET_FETCH_BUKASEND_MITRA_AGENT_STATUS(state, status) {
    state.fetchBukasendMitraAgentStatus = status;
  },
};

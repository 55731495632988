import { API_BASE_URL } from 'shared_js/constant/bukasend';

const url = `${API_BASE_URL}/open-shipments/extensions/orders`;

const interfaces = {
  enqueueSyncOrders(data) {
    return this.post(`${url}/sync`, 'public', { data });
  },
};

export default interfaces;

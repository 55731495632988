import { API_BASE_URL } from 'shared_js/constant/bukasend';
import queryString from 'shared_js/helpers/query-helper';

const aggregateURL = `${API_BASE_URL}/aggregate`;

const interfaces = {
  getCourierCategories(params) {
    return this.get(`${API_BASE_URL}/couriers/categories`, 'public', { params });
  },
  getCouriers(params) {
    return this.get(`${API_BASE_URL}/open-shipments/couriers`, 'public', { params });
  },
  getBulkCouriers(parameters) {
    const aggregate = parameters.reduce(
      (res, params, index) => ({
        ...res,
        [index]: {
          method: 'GET',
          path: `/open-shipments/couriers?${queryString(params)}`,
        },
      }),
      {},
    );

    return this.post(aggregateURL, 'public', { data: { aggregate } });
  },
  getBaseCouriers() {
    return this.get(`${API_BASE_URL}/couriers`, 'public');
  },
  getJutawanBulkCouriers(parameters) {
    const aggregate = parameters.reduce(
      (res, params, index) => ({
        ...res,
        [index]: {
          method: 'GET',
          path: `/dropshipments/couriers?${queryString(params)}`,
        },
      }),
      {},
    );

    return this.post(aggregateURL, 'public', { data: { aggregate } });
  },
  getCourierContacts() {
    return this.get(`${API_BASE_URL}/helps/courier-contacts`, 'public');
  },
};

export default interfaces;

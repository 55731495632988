import { API_BASE_URL } from 'shared_js/constant/bukasend';

const url = `${API_BASE_URL}/_exclusive/bukasend-delivery-insurances`;

const interfaces = {
  getProductInsurances(params = {}) {
    return this.get(`${url}/products`, 'user', { params });
  },
  getTransaction(id) {
    return this.get(`${url}/transactions/${id}`, 'user');
  },
};

export default interfaces;

/* eslint-disable */
const API_BASE_URL = global.serverApp ? global.serverApp.apiURL : global.APP ? global.APP.apiURL : '';
const EXCLUSIVE_URL = `${API_BASE_URL}/_exclusive`;
const INTERFACE_KEY = 'xhrBeagle';
const IS_DEV_MODE = global.APP ? global.APP.isDevelopment : process.env.NODE_ENV !== 'production';
const WRAP_API = global.API ? global.API.wrap : null;
const USER_ID =
  global.serverApp && global.serverApp.user
    ? global.serverApp.user.id.toString(36)
    : global.USER
    ? global.USER.id36
    : '';
const IDENTITY = global.serverApp ? global.serverApp.userIdentity : global.USER ? global.USER.identity : '';
const DEFAULT_ERROR_MESSAGE = 'Terjadi kesalahan, silahkan coba kembali!';
const IS_SSR = typeof window === 'undefined';

module.exports = {
  API_BASE_URL,
  EXCLUSIVE_URL,
  INTERFACE_KEY,
  IS_DEV_MODE,
  WRAP_API,
  USER_ID,
  IDENTITY,
  DEFAULT_ERROR_MESSAGE,
  IS_SSR,
};

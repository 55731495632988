export default {
  addresses: [],
  fetchAddressStatus: false,

  provinces: [],
  fetchProvincesStatus: '',

  cities: {},
  fetchCitiesStatus: '',

  districts: {},
  fetchDistrictsStatus: '',

  postalCodes: {},
  fetchPostalCodesStatus: '',

  geolocationFlagHash: {},
  fetchBulkAdditionalAttributesStatus: '',
};

import { mapState, mapMutations, mapGetters } from 'vuex';
import { NEO_CONFIG_KEYS } from 'shared_js/constant/toggles';

export default {
  computed: {
    ...mapState('configuration', ['customFieldWhitelistUsers']),
    ...mapGetters('configuration', ['userIsCustomFieldWhitelist']),
  },
  methods: {
    ...mapMutations('configuration', ['SET_CUSTOM_FIELD_WHITELIST_USERS']),
    setCustomFieldWhitelistUsers({ apiResult }) {
      const { data } = apiResult;
      const config = data.find(d => d.id === NEO_CONFIG_KEYS.BUKASEND_CUSTOM_FIELD_WHITELIST_USERS);
      this.SET_CUSTOM_FIELD_WHITELIST_USERS(config ? config.data.user_ids : []);
    },
  },
};

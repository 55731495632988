export default {
  SET_ADDRESS(state, data) {
    const addresses = state.addresses || [];
    state.addresses = addresses.concat(data);
  },
  SET_TOTAL_ADDRESS(state, total) {
    state.totalAddress = total;
  },
  UPDATE_ADDRESS(state, data) {
    if (!state.addresses) {
      state.addresses = [data];
    } else {
      const index = state.addresses.findIndex(r => r.id === data.id);

      if (index !== -1) {
        state.addresses.splice(index, 1);
      }

      state.addresses.unshift(data);
    }
  },
  SET_FETCH_ADDRESS_STATUS(state, status) {
    state.fetchAddressStatus = status;
  },
  DELETE_ADDRESS(state, index) {
    state.addresses.splice(index, 1);
    state.totalAddress -= 1;
  },
  RESET_ADDRESS(state) {
    state.addresses = null;
    state.totalAddress = 0;
    state.fetchAddressStatus = '';
  },
  RESET_MAIN_ADDRESS(state) {
    (state.addresses || []).map(address => {
      address.main_address = false;

      return address;
    });
  },
};

import { API_BASE_URL } from 'shared_js/constant/bukasend';

const url = `${API_BASE_URL}/invoices`;

const interfaces = {
  getInvoices(params) {
    return this.get(url, 'user', { params });
  },
  getInvoice(id) {
    return this.get(`${url}/${id}`, 'user');
  },
  createInvoice(data) {
    return this.post(url, 'public', { data });
  },
  createJutawanInvoice(data) {
    return this.post(`${API_BASE_URL}/dropshipments/invoices`, 'public', { data });
  },
};

export default interfaces;

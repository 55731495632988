export default {
  SET_COURIER_CATEGORIES(state, data) {
    state.courierCategories = data;
  },
  SET_FETCH_COURIER_CATEGORY_STATUS(state, status) {
    state.fetchCourierCategoryStatus = status;
  },
  SET_COURIERS(state, data) {
    state.couriers = data;
  },
  SET_FETCH_COURIER_STATUS(state, status) {
    state.fetchCourierStatus = status;
  },
  SET_HASH_COURIERS(state, { hash, data }) {
    state.hashCouriers[hash] = data;
  },
  RESET_HASH_COURIERS(state) {
    state.hashCouriers = {};
  },
  SET_COURIER_ERROR(state, error) {
    state.courierError = error;
  },
  SET_COURIER_CONTACTS(state, data) {
    state.courierContacts = data;
  },
  SET_FETCH_COURIER_CONTACTS_STATUS(state, status) {
    state.fetchCourierContactsStatus = status;
  },
  SET_EXCLUDE_PICKUP_FAILED_COURIERS(state, payload) {
    state.excludePickupFailedCouriers = payload;
  },
  SET_COURIER_OPS_TIME_CONFIG(state, payload) {
    state.courierOpsTimeConfig = payload;
  },
  SET_BACKEND_COURIER_OPS_TIME_CONFIG(state, payload) {
    state.backendCourierOpsTimeConfig = payload;
  },
  SET_REGISTERED_COURIERS(state, payload) {
    state.registeredCouriers = payload;
  },
  SET_PICKUP_GUARANTEE_CONFIG(state, payload) {
    state.pickupGuaranteeConfig = payload;
  },
  SET_COD_COURIERS_COUNT(state, { serviceType, count }) {
    state.codCouriersCount = {
      ...state.codCouriersCount,
      [serviceType]: count,
    };
  },
  RESET_COD_COURIERS_COUNT(state) {
    state.codCouriersCount = {
      dropoff: null,
      pickup: null,
    };
  },
};

import { IS_SSR } from 'shared_js/constant/general';

export const setBackgroundColor = (selector = 'html', bgColor = '#f5f5f5') => {
  if (IS_SSR) {
    return;
  }
  document.querySelector(selector).style.backgroundColor = bgColor;
};

export default {
  setBackgroundColor,
};

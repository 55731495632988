import { API_BASE_URL } from 'shared_js/constant/bukasend';

const apiPath = '/open-shipments/sender-addresses';
const url = `${API_BASE_URL}${apiPath}`;

const interfaces = {
  updateAdditionalAttributes(data) {
    return this.patch(`${url}/${data.sender_address_id}/additional-attributes`, 'public', { data });
  },
};

export default interfaces;

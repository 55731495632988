/* eslint-disable import/prefer-default-export */
import { WEIGHT_UNITS } from 'shared_js/constant/bukasend';

export const DEFAULT_TRANSACTION = {
  id: null,
  order_name: '',
  order_total: 1,

  order_price: 0,
  insured: false,

  weight: 1000,
  weightInput: 1000,
  weight_unit: WEIGHT_UNITS.KG.value,
  volumetric: false,
  height: 0,
  width: 0,
  length: 0,

  dropship: false,
  selectedInsuranceProduct: [],
  insuranceAutoOptedIn: false,
  senderAlias: {
    name: '',
    phone: '',
  },
  notes: '',
  from: {
    id: null,
    name: '',
    title: '',
    phone: '',
    email: '',
    address: '',
    area: '',
    city: '',
    province: '',
    post_code: '',
    latitude: null,
    longitude: null,
    geolocation_set_by_user: false,
  },
  to: {
    id: null,
    name: '',
    title: '',
    phone: '',
    email: '',
    address: '',
    area: '',
    city: '',
    province: '',
    post_code: '',
    latitude: null,
    longitude: null,
    geolocation_set_by_user: false,
  },
  originalSenderLocation: null,
  hasSetPickupLocation: false,
  hasSetDestinationLocation: false,
  courier: {
    service: '',
    service_name: '',
    eta: '',
    price: 0,
    price_original: 0,
    courier_service_type: '',
  },
  amount: {
    total: 0,
    shipping: 0,
    insurance: 0,
    discrepancy: 0,
    order: 0,
  },
  inputRecipientType: '1',
  districts: null,
  saveContact: false,
  custom_fields: null,
  pickup_request_time: null,
  detail_pickup_date: null,
  detail_pickup_time: '#',
  pickupRequestTimeError: null,
  contentCategory: null,

  // rebook
  rebookData: null,

  // new desktop
  hasClickCourierSection: false,

  isNonLoginInput: false,

  codChecked: false,
  codAmount: '',
  codCourierUnavailable: false,
  codCouriersCount: null,

  // draft
  draftData: null,

  uniqueId: '',

  // limit bulk upload
  bulkUploadEligibilityHash: null,
};

<template>
  <div class="c-header">
    <div v-if="showLeftButton" class="c-header--left qa-header-back">
      <a :href="leftButtonUrl" @click="leftButtonClick">
        <bl-icon-av :name="leftIconName" :color="leftIconColor" />
      </a>
    </div>

    <div :class="{ 'u-mrgn-left--4': !showLeftButton }" class="c-header--title">
      <slot v-if="!title && $slots.title" name="title" />
      <template v-if="title && !$slots.title">
        {{ title }}
      </template>
    </div>

    <div v-if="$slots.rightMenu" class="c-header--right">
      <slot name="rightMenu" />
    </div>
  </div>
</template>
<script>
/* eslint-disable no-script-url */
import { BlIconAv } from '@bukalapak/bazaar-visual';

export default {
  name: 'CustomHeader',
  components: {
    BlIconAv,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    showLeftButton: {
      type: Boolean,
      default: true,
    },
    leftButtonUrl: {
      type: String,
      default: 'javascript:void(0);',
    },
    leftIconName: {
      type: String,
      default: 'ico_back_ios',
    },
    leftIconColor: {
      type: String,
      default: 'active-darker',
    },
  },
  mounted() {
    this.$emit('header-mounted');
  },
  methods: {
    leftButtonClick() {
      if (this.$listeners && this.$listeners['left-button-click']) {
        this.$emit('left-button-click');
      }
    },
  },
};
</script>
<style src="./index.scss" lang="scss" scoped></style>

export default {
  transactions(state, _, rootState) {
    return state.transactions[rootState.transactions.activeStatus];
  },
  fetchTransactionsStatus(state, _, rootState) {
    return state.fetchTransactionsStatus[rootState.transactions.activeStatus];
  },
  total(state, _, rootState) {
    return state.total[rootState.transactions.activeStatus];
  },
};

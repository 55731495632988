import { IS_SSR } from 'shared_js/constant/general';

export function scrollTop(behavior = 'instant') {
  if (IS_SSR) {
    return;
  }
  // reference: https://hospodarets.com/native_smooth_scrolling
  const isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style;
  if (isSmoothScrollSupported) {
    window.scrollTo({ top: 0, left: 0, behavior });
  } else {
    window.scrollTo(0, 0);
  }
}

export function scrollBottom(element = null, behavior = 'instant') {
  if (IS_SSR) {
    return;
  }
  const selector = element || window;
  const scrollHeight = element ? element.scrollHeight : document.body.scrollHeight;
  const isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style;
  if (isSmoothScrollSupported) {
    selector.scrollTo({ top: scrollHeight, behavior });
  } else {
    selector.scrollTo(0, scrollHeight);
  }
}

export function scrollBehavior(to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition;
  }
  return { x: 0, y: 0 };
}

export function scrollBehaviorBukaSend(to, from, savedPosition) {
  if (to.name === 'snk_insurance_page') {
    return { x: 0, y: 0 };
  }
  return savedPosition;
}

export function scrollToFirstError(opts = {}, el = '.is-error') {
  if (IS_SSR) {
    return;
  }
  const firstError = document.querySelector(el);
  if (firstError) {
    firstError.scrollIntoView(opts);
  }
}

export function checkBlSheetBodyScroll() {
  setTimeout(() => {
    const activeCount = document.querySelectorAll('.bl-sheet.is-active:not(.js-map-location-modal)').length;
    document.body.style.overflow = activeCount ? 'hidden' : 'auto';
  }, 800);
}

export default {
  scrollTop,
  scrollBottom,
  scrollBehavior,
  scrollToFirstError,
  checkBlSheetBodyScroll,
};

/* eslint-disable import/prefer-default-export */
const PREFIX = 'bukasend_';

export const LOCAL_STORAGE_KEYS = {
  HAS_SEEN_RESEND_BUTTON_COACHMARK_KEY: `${PREFIX}has_seen_resend_button_coachmark`,
  HAS_SEEN_MULTISENDER_ADDRESS_KEY: `${PREFIX}has_seen_multisender_address_coachmark`,
  HAS_SEEN_HIDE_CASHBACK_COACHMARK_KEY: `${PREFIX}has_seen_hide_cashback_coachmark`,
  HAS_SEEN_SHOW_CASHBACK_COACHMARK_KEY: `${PREFIX}has_seen_show_cashback_coachmark`,
  MEMBERSHIP_USERS_TIER: `${PREFIX}users_membership_tier`,
  HAS_SEEN_COD_TNC_KEY: `${PREFIX}has_seen_cod_tnc`,
  HAS_SEEN_COD_COACHMARK_KEY: `${PREFIX}has_seen_cod_coachmark`,
  DRAFT_ROUTE_COACHMARK_SEEN_KEY: `${PREFIX}draft_route_coachmark_seen`,
  EMPTY_ROUTE_DRAFT_TOOLTIP_SEEN_KEY: `${PREFIX}empty_route_draft_toolip_seen`,
  HOME_PAGE_DWEB_COACHMARK_SEEN: `${PREFIX}home_page_dweb_coachmark_seen`,
  HAS_SEEN_MADO_DOPE_COACHMARK_KEY: `${PREFIX}has_seen_mado_dope_coachmark`,
  HAS_SEEN_MADO_ADD_AWB_COACHMARK_KEY: `${PREFIX}has_seen_mado_add_awb_coachmark`,
  KYC_HOME_PAGE_VALIDATION: `${PREFIX}kyc_home_page_validation`,
  DISCREPANCY_HOME_PAGE_VALIDATION: `${PREFIX}discrepancy_home_page_validation`,
  LANDING_COD_BANNER_KEY: `${PREFIX}landing_cod_banner_key`,
  LOGOUT_USER_GENERATED_ID: `${PREFIX}logout_user_generated_id`,
  HAS_SEEN_SHARE_LINK_COACHMARK: `${PREFIX}has_seen_share_link_coachmark`,
  NEW_USER_HAS_SEEN_MEMBERSHIP_COACHMARK: `${PREFIX}new_user_has_seen_membership_coachmark`,
  NEW_USER_HAS_SEEN_PROFILE_MEMBERSHIP_COACHMARK: `${PREFIX}new_user_has_seen_profile_membership_coachmark`,
  HAS_SEEN_MITRA_WALLET_ACTIVATION_COACHMARK: `${PREFIX}has_seen_mitra_wallet_activation_coachmark`,
  HAS_SEEN_MITRA_WALLET_ACTIVATED_COACHMARK: `${PREFIX}has_seen_mitra_wallet_activated_coachmark`,
  HAS_SEEN_MITRA_WALLET_ACTIVATION_PROFILE_COACHMARK: `${PREFIX}has_seen_mitra_wallet_activation_profile_coachmark`,
  HAS_SEEN_MITRA_WALLET_ACTIVATED_PROFILE_COACHMARK: `${PREFIX}has_seen_mitra_wallet_activated_profile_coachmark`,
  WELCOME_NON_MEMBER_TIER_SEEN: `${PREFIX}welcome_non_member_tier_seen`,
  NEW_USER_KYC_VERIFIED_SEEN: `${PREFIX}new_user_kyc_verified_seen`,
};

/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
export const generateGuid = () => {
  const rand4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);

  return `${rand4()}${rand4()}-${rand4()}-${rand4()}-${rand4()}-${rand4()}${rand4()}${rand4()}`;
};

export const hashCode = string => {
  let hash = 0;
  let chr;

  if (string.length === 0) {
    return hash;
  }

  for (let i = 0; i < string.length; i++) {
    chr = string.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export default {
  generateGuid,
  hashCode,
};

import { API_BASE_URL } from 'shared_js/constant/bukasend';
import getBukalapakIdentityHeader from 'shared_js/helpers/bl-identity-helper';

const url = `${API_BASE_URL}/open-shipments/transactions`;
const recapsUrl = `${API_BASE_URL}/open-shipments/transaction-recaps`;
const aggregateURL = `${API_BASE_URL}/aggregate`;

const interfaces = {
  getBulkTransactions(ids) {
    const aggregate = ids.reduce(
      (res, id) => ({
        ...res,
        [id]: {
          method: 'GET',
          path: `/open-shipments/transactions/${id}`,
        },
      }),
      {},
    );

    return this.post(aggregateURL, 'public', {
      data: { aggregate },
    });
  },
  getShippingInfo({ id, bookingCode }) {
    return this.get(`${url}/${id}/shipping-info`, 'public', {
      params: {
        booking_code: bookingCode,
      },
    });
  },
  createTransaction(data) {
    const headers = getBukalapakIdentityHeader();
    return this.post(url, 'public', { data, headers });
  },
  createBulkTransactions(data) {
    const aggregate = data.reduce(
      (res, transaction, i) => ({
        ...res,
        [i]: {
          method: 'POST',
          path: '/open-shipments/transactions',
          body: transaction,
        },
      }),
      {},
    );

    return this.post(aggregateURL, 'public', {
      data: { aggregate },
    });
  },
  getTransactions(params) {
    return this.get(url, 'public', { params });
  },
  getTransaction(trxId) {
    return this.get(`${url}/${trxId}`, 'public');
  },
  updateMPTransactionStatus(id, data) {
    return this.put(`${API_BASE_URL}/transactions/${id}/status`, 'public', { data });
  },
  getMPTransaction(id) {
    return this.get(`${API_BASE_URL}/transactions/${id}`, 'public');
  },
  getMPTransactionReturns(id) {
    return this.get(`${API_BASE_URL}/transactions/${id}/returns`, 'public');
  },
  getMPTransactionReturnAddress(id) {
    return this.get(`${API_BASE_URL}/transactions/${id}/return-address`, 'public');
  },
  cancelBukasendTransaction(id, shippingState) {
    const data = {
      shipping_state: shippingState,
    };
    return this.patch(`${url}/${id}/cancellations`, 'public', { data });
  },
  getDiscrepancyTransactions() {
    return this.get(`${API_BASE_URL}/open-shipments/discrepancy-transactions`, 'public');
  },
  getTransactionSummaries(params) {
    return this.get(`${url}/summaries`, 'public', { params });
  },
  getTransactionRecaps() {
    return this.get(recapsUrl, 'public');
  },
  getTransactionRecapDownloadDetails(id) {
    return this.get(`${recapsUrl}/${id}/downloads`, 'public');
  },
  getTransactionVoucherCashbackDetails(id) {
    return this.get(`${url}/cashback-vouchers/${id}`, 'public');
  },
  retrieveTransactionRecipients(params) {
    return this.get(`${url}/receiver-names`, 'user', { params });
  },
};

export default interfaces;

import { API_BASE_URL } from 'shared_js/constant/bukasend';

const interfaces = {
  validate(data) {
    return this.post(`${API_BASE_URL}/vouchers/validations`, 'user', { data });
  },
  claimAllCoupons() {
    return this.post(`${API_BASE_URL}/promos/coupons/claims`, 'user');
  },
  getClaimedCoupons() {
    return this.get(`${API_BASE_URL}/promos/coupons/claims`, 'user', {
      params: {
        product_types: ['buka-pengiriman'],
        with: ['amount', 'percentage'],
      },
    });
  },
};

export default interfaces;

const BUKASEND_CLEANUP_CTA = 'BEE-7212_ALL_Bukasend_Cleanup_CTA';
const HOME_VOUCHER_BANNER = 'BEE-12774_ALL_Bukasend_Home_Voucher_Banner';
const COST_CHECK_PARENT_PAGE = 'BEE-15793_ALL_AB_Test_Cek_Ongkir_Widget_in_the_parent_page';

export default {
  experiments: {},
  fetchExperimentStatus: {
    [BUKASEND_CLEANUP_CTA]: null,
    [HOME_VOUCHER_BANNER]: '',
    [COST_CHECK_PARENT_PAGE]: '',
  },
  experimentKeys: {
    BUKASEND_CLEANUP_CTA,
    HOME_VOUCHER_BANNER,
    COST_CHECK_PARENT_PAGE,
  },
};

import { API_BASE_URL } from 'shared_js/constant/general';

const url = `${API_BASE_URL}/agents`;

const interfaces = {
  getMe() {
    return this.get(`${url}/me`, 'user');
  },
};

export default interfaces;

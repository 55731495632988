/* eslint no-bitwise: ["error", { "allow": ["&"] }] */
import { mapState, mapGetters } from 'vuex';
import UserAgentMixin from 'shared_js/mixins/user-agent';
import curry from 'shared_js/helpers/curry-helper';

const bigInt = require('big-integer');

export default {
  mixins: [UserAgentMixin],
  data() {
    return {
      FEATURE_KEYS: {
        RECAP_LANDING: 'recap_landing',
        RECAP_HISTORY: 'recap_history',
        RECAP_DOWNLOAD: 'recap_download',
        MARKETING_MATERIAL: 'marketing_material',
        THERMAL_RESI: 'thermal_resi',
        FLOATING_BUTTON_CS: 'floating_button_cs',
      },
    };
  },
  computed: {
    ...mapState('configuration', ['rolePermission']),
    ...mapState('toggle', ['togglePermissionsByRoleEnabled', 'togglePartnerHidePromoEnabled']),
    ...mapGetters('configuration', ['isRolePartner']),
    currentRoleName() {
      return curry(['name'], this.rolePermission);
    },
    isRoleAgent() {
      return this.currentRoleName === 'agent';
    },
    isRoleSuperAgent() {
      return this.currentRoleName === 'super_agent';
    },
    isAgent() {
      return (
        (this.togglePermissionsByRoleEnabled && (this.isRoleAgent || this.isRoleSuperAgent)) ||
        (!this.togglePermissionsByRoleEnabled && this.isBLMitra)
      );
    },
    hidePromoIfPartner() {
      return this.togglePartnerHidePromoEnabled && this.isRolePartner;
    },
  },
  methods: {
    isPermissionAllowed(featureKey = '') {
      if (this.togglePermissionsByRoleEnabled) {
        const rolePermissions = curry(['permissions'], this.rolePermission);
        const permissionsMapping = curry(['permissions_mapping'], this.rolePermission) || {};
        const permissions = bigInt(rolePermissions);
        const featurePermission = bigInt(permissionsMapping[featureKey]);

        return !!(permissions & featurePermission);
      }

      return true;
    },
  },
};

export default {
  recipientAddresses: null,
  totalRecipientAddress: 0,
  fetchRecipientAddressStatus: null,

  searchKeyword: '',
  searchAddresses: null,

  showRecipientLocation: false,
  recipientLocation: {
    address: '',
    detailAddress: '',
    province: undefined,
    city: undefined,
    area: undefined,
    postCode: null,
    latitude: null,
    longitude: null,
  },
};

import { API_BASE_URL } from 'shared_js/constant/bukasend';

const apiPath = '/open-shipments/mado';
const url = `${API_BASE_URL}${apiPath}`;

const interfaces = {
  retrieveBookings(params = {}) {
    return this.get(`${url}/bookings`, 'public', { params });
  },
  createInquiry(data) {
    return this.post(`${url}/packages/inquiries`, 'public', { data });
  },
  retrieveBookingById(id) {
    return this.get(`${url}/bookings/${id}`, 'public');
  },
  retrieveShippingHistories(id) {
    return this.get(`${url}/bookings/${id}/shipping-histories`, 'public');
  },
};

export default interfaces;

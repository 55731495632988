import aggregateApis from 'shared_js/api/public/aggregate';
import { getUserId } from 'shared_js/helpers/user-helper';

export default {
  async fetchReturnAddress({ commit, state }, params = {}) {
    const { transactionId, discussionId } = params;
    if (!transactionId || !discussionId || state.detailMarketplaceTransactions[transactionId]) {
      return;
    }

    try {
      const aggregates = {
        buyerAddress: {
          method: 'GET',
          path: `/returns/${discussionId}/addresses?as_buyer=true`,
        },
        sellerAddress: {
          method: 'GET',
          path: `/returns/${discussionId}/addresses?as_buyer=false`,
        },
        returns: {
          method: 'GET',
          path: `/transactions/${transactionId}/returns`,
        },
        transaction: {
          method: 'GET',
          path: `/transactions/${transactionId}`,
        },
      };
      const response = await aggregateApis.aggregate(aggregates);
      const { data, meta } = response;
      const results = Object.keys(data).reduce((result, key) => {
        let outputs = result;
        if (meta[key].http_status === 200) {
          outputs = {
            ...result,
            [key]: data[key],
          };
        }
        return outputs;
      }, {});
      const { buyerAddress, sellerAddress, transaction, returns } = results;

      if (transaction && buyerAddress && sellerAddress) {
        const isBuyer = returns.buyer_id === getUserId();
        const address = isBuyer ? sellerAddress : buyerAddress;

        const commitData = {
          [transactionId]: {
            ...transaction,
            returnAddress: {
              ...address,
              post_code: address.postal_code,
              area: address.district,
            },
            returns: returns ? { ...returns } : null,
          },
        };
        commit('SET_DETAIL_MP_TRANSACTIONS', commitData);
      }
    } catch (err) {
      throw err;
    }
  },
};

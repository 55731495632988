import courierApis from 'shared_js/api/public/couriers';
import { hashCode } from 'shared_js/helpers/guid-helper';
import { getErrorMessage } from 'shared_js/helpers/error-helper';

export default {
  async fetchCourierCategories({ commit, state }, params) {
    if ([null, true].includes(state.fetchCourierCategoryStatus)) {
      return;
    }

    commit('SET_FETCH_COURIER_CATEGORY_STATUS', null);

    try {
      const response = await courierApis.getCourierCategories(params);
      let status = false;

      if (response.meta.http_status === 200) {
        status = true;
        const { data } = response;

        commit('SET_COURIER_CATEGORIES', data);
      }
      commit('SET_FETCH_COURIER_CATEGORY_STATUS', status);
    } catch (e) {
      commit('SET_FETCH_COURIER_CATEGORY_STATUS', false);
    }
  },
  async fetchCouriers({ commit, dispatch, state }, params) {
    commit('SET_COURIER_ERROR', null);
    commit('SET_FETCH_COURIER_STATUS', null);

    const { hash: hashParam } = params;
    delete params.hash;
    const hash = hashParam || hashCode(JSON.stringify(params));

    if (state.hashCouriers[hash]) {
      commit('SET_COURIERS', state.hashCouriers[hash]);
      commit('SET_FETCH_COURIER_STATUS', true);

      return;
    }

    commit('SET_COURIERS', []);

    try {
      const response = await courierApis.getCouriers(params);
      let status = false;

      let data = [];
      if (response.meta.http_status === 200) {
        status = true;
        ({ data } = response);

        data = data.sort((a, b) => a.order - b.order);
      }
      dispatch('setCouriers', { hash, couriers: data });
      commit('SET_FETCH_COURIER_STATUS', status);
    } catch (e) {
      commit('SET_COURIER_ERROR', getErrorMessage(e));
      commit('SET_FETCH_COURIER_STATUS', false);
    }
  },
  async fetchCourierContacts({ commit, state }) {
    if ([null, true].includes(state.fetchCourierContactsStatus)) {
      return;
    }

    commit('SET_FETCH_COURIER_CONTACTS_STATUS', null);

    try {
      const response = await courierApis.getCourierContacts();
      let status = false;

      if (response.meta.http_status === 200) {
        status = true;
        const { data } = response;

        commit('SET_COURIER_CONTACTS', data);
      }
      commit('SET_FETCH_COURIER_CONTACTS_STATUS', status);
    } catch (e) {
      commit('SET_FETCH_COURIER_CONTACTS_STATUS', false);
    }
  },
  initCodCouriersCountFromTrx({ commit, rootState }) {
    const { form } = rootState;
    if (form && form.transaction && form.transaction.codCouriersCount) {
      const { pickup, dropoff } = form.transaction.codCouriersCount;
      commit('SET_COD_COURIERS_COUNT', { serviceType: 'pickup', count: pickup });
      commit('SET_COD_COURIERS_COUNT', { serviceType: 'dropoff', count: dropoff });
      commit('form/SET_TRX_COD_COURIERS_COUNT', null, { root: true });
    }
  },
  setCouriers({ commit }, { hash = null, couriers = [] }) {
    commit('SET_COURIERS', couriers);
    if (hash) {
      commit('SET_HASH_COURIERS', { hash, data: couriers });
    }
  },
};

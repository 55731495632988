import trackerApi from 'shared_js/api/public/tracker';

const sendTrackRegisterInvoiceData = async invoice => {
  try {
    const product = invoice.transactions[0];
    const data = {
      invoice_id: invoice.id,
      product_type: product ? product.type : '',
    };
    await trackerApi.trackInvoiceRegister(data);
  } catch (e) {
    console.error(e);
  }
};

export default sendTrackRegisterInvoiceData;

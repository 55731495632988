import { BUKASEND_TRANSACTION_TYPE } from 'shared_js/constant/bukasend';
import { isLoggedIn } from 'shared_js/helpers/user-helper';
import curry from 'shared_js/helpers/curry-helper';

export default {
  packageNumber(state) {
    return state.activeIdx !== null ? state.activeIdx + 1 : state.transactions.length + 1;
  },
  isCourierGrab({ transaction }) {
    return transaction.courier.service === 'grab';
  },
  pickupLocation({ transaction }) {
    if (transaction.hasSetPickupLocation) {
      const { latitude, longitude } = transaction.from;
      return { latitude, longitude };
    }
    return {};
  },
  destinationLocation({ transaction }) {
    if (transaction.hasSetDestinationLocation) {
      const { latitude, longitude } = transaction.to;
      return { latitude, longitude };
    }
    return {};
  },
  hasPickupLocation(_, { pickupLocation }) {
    const { latitude, longitude } = pickupLocation;
    return !!latitude && !!longitude;
  },
  hasDestinationLocation(_, { destinationLocation }) {
    const { latitude, longitude } = destinationLocation;
    return !!latitude && !!longitude;
  },
  hasPickupAndDestinationLocation(_, { hasPickupLocation, hasDestinationLocation }) {
    return hasPickupLocation && hasDestinationLocation;
  },
  rebookTitle(state, _, { configuration }) {
    const { rebookConfig = {} } = configuration || {};
    const { rebookEnabled = false, resendEnabled = false } = rebookConfig || {};
    if (rebookEnabled || resendEnabled) {
      let transaction = null;
      if (state.transactions.length && state.activeIdx !== 0) {
        transaction = state.transactions[0];
      } else {
        ({ transaction } = state);
      }
      const { title } = transaction.rebookData || {};
      return title || 'Kirim lagi';
    }
    return '';
  },
  isDraft(state) {
    return Boolean(state.transaction.draftData) && Boolean(state.transaction.draftData.id);
  },
  isShopifyDraft(state) {
    return Boolean(state.transaction.draftData) && Boolean(state.transaction.draftData.extPlatform === 'Shopify');
  },
  isSavingDraft(state) {
    return state.saveDraftStatus === null;
  },
  hasErrorDrafts(state) {
    const errorDraftIndex = state.transactions.findIndex(trx => trx.draftData && trx.draftData.isError);
    return errorDraftIndex !== -1;
  },
  totalAllTransactionCODAmount({ transactions }) {
    return transactions.reduce((total, trx) => total + parseInt(trx.codAmount || 0, 10), 0);
  },
  voucherValidationTransactions({ transactions }) {
    return transactions.map(transaction => {
      const {
        id,
        codChecked,
        codAmount,
        courier = {},
        selectedInsuranceProduct: insuranceProducts,
        to = {},
      } = transaction;
      const { total, shipping } = transaction.amount;
      const cod = codChecked && codAmount && codAmount > 0;
      const insurance =
        insuranceProducts && insuranceProducts.length
          ? insuranceProducts.reduce((res, product) => res + product.amount, 0)
          : 0;
      return {
        id,
        type: BUKASEND_TRANSACTION_TYPE,
        bukasend_details: { cod },
        shipment: {
          courier: courier.service_name,
          address: {
            province: to.province,
            city: to.city,
          },
        },
        amount: {
          total: total + insurance,
          details: { shipping, insurance },
        },
      };
    });
  },
  isMultiplePackageActive(state, getters, rootState, rootGetters) {
    if (rootGetters['configuration/isRolePartner'] || !rootState.toggle.toggleLimitBulkUploadUserEnabled) {
      return true;
    }

    return Boolean(
      isLoggedIn() && curry(['benefits', 'bulk_upload', 'active'], rootGetters['configuration/currentMembership']),
    );
  },
};

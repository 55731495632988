const wrapApiHandler = (interfaceObject, hooks) => {
  if (!hooks) {
    return interfaceObject;
  }

  const newInterfaceObject = {};
  const interfaceKeys = Object.keys(interfaceObject);

  interfaceKeys.forEach(key => {
    newInterfaceObject[key] = (...options) =>
      new Promise((resolve, reject) => {
        const { beforeSuccessResolved, onError } = hooks;

        interfaceObject[key](...options)
          .then(response => {
            if (typeof beforeSuccessResolved === 'function') {
              beforeSuccessResolved(key, response);
            }

            resolve(response);
          })
          .catch(error => {
            if (typeof onError === 'function') {
              onError(error, reject);
            }

            reject(error);
          });
      });
  });

  return newInterfaceObject;
};

export default wrapApiHandler;

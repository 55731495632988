import { API_BASE_URL } from 'shared_js/constant/general';

const interfaces = {
  trackInvoiceRegister(data) {
    const user = global.blGlobal ? global.blGlobal.user : global.USER;
    const headers = { 'Bukalapak-Identity': user.identity };
    return this.post(`${API_BASE_URL}/offline-conversion-trackers/invoice-registers`, 'public', { data, headers });
  },
};

export default interfaces;

/* eslint-disable global-require */
import { removeTrailingSlash } from 'shared_js/helpers/url-helper';
import { createRouter } from './config/router';

const NON_LOGIN_SSR_PATHS = ['/bukasend', '/bukasend/delivery-cost-check'];
const SSR_PATHS = [
  '/bukasend/kurir',
  '/bukasend/business',
  '/bukasend/kirim-barang',
  '/bukasend/agen-bukasend',
  '/bukasend/mengapa-bukasend',
];

// eslint-disable-next-line
export const createApp = ({ Vue, Vuex, VueRouter, ssr = false, path = '', user = null, ssrEnabled = false }) => {
  const isLoggedIn = !!(user && user.id);
  const PermissionsMixin = require('./mixins/permissions').default;
  const tracker = require('./tracker').default;
  const createStore = require('./store').default;
  const { mountPointId } = require('./app-constant');

  const additionalRouterParams = {};

  Vue.prototype.$bus = new Vue();
  Vue.prototype.$tracker = tracker;
  Vue.use(Vuex);
  Vue.mixin(PermissionsMixin);

  if (!ssr) {
    require('@bukalapak/bazaar-mweb/dist/bazaar.css');
    require('@bukalapak/bazaar-snowflake-mweb/dist/bazaar.css');
    require('vue-tour/dist/vue-tour.css');
    require('shared_css/quill.core.scss');

    const VeeValidate = require('vee-validate').default;
    const VeeValidateId = require('vee-validate/dist/locale/id');
    const VueTour = require('vue-tour').default;
    const VueYoutube = require('vue-youtube').default;
    const { scrollBehaviorBukaSend } = require('shared_js/helpers/scroll-helper').default;

    Vue.use(VeeValidate, { locale: 'id' });
    VeeValidate.Validator.localize({ id: VeeValidateId });
    Vue.use(VueTour);
    Vue.use(VueYoutube);
    additionalRouterParams.scrollBehavior = scrollBehaviorBukaSend;
  }

  Vue.use(VueRouter);
  const router = createRouter({ Vue, VueRouter, additionalRouterParams });

  let VueParams = {};
  const store = createStore(Vuex);
  const isLoggedOutSSR = !isLoggedIn && NON_LOGIN_SSR_PATHS.includes(removeTrailingSlash(path));
  const isPathSSR = SSR_PATHS.some(ssrPath => path.includes(ssrPath));
  const isAllowedUrlSSR = ssr && ssrEnabled && (isLoggedOutSSR || isPathSSR);

  if (isAllowedUrlSSR || !ssr) {
    const BukasendApp = require('./App').default;

    VueParams = {
      router,
      store,
      components: {
        BukasendApp,
      },
      template: `<bukasend-app />`,
    };
  } else {
    VueParams = {
      store,
      template: `<div id="${mountPointId}"></div>`,
    };
  }

  const app = new Vue(VueParams);
  const result = {
    app,
    store,
    router,
  };

  return result;
};

import extRegisterApis from 'shared_js/api/public/extensions/register';
import extOrdersApis from 'shared_js/api/public/extensions/orders';
import shopifyAuthApis from 'shared_js/api/public/extensions/shopify-auth';
import { SYNC_ORDER_STATUS } from 'shared_js/constant/bukasend';

export default {
  async fetchRegisteredAccount({ state, commit, dispatch }, { setSyncInProgressShops = false } = {}) {
    commit('SET_FETCH_REGISTERED_ACCOUNT_STATUS', null);
    try {
      const response = await extRegisterApis.fetchRegisteredAccount();
      dispatch('setRegisteredAccount', response);
      commit('SET_FETCH_REGISTERED_ACCOUNT_STATUS', true);
      if (setSyncInProgressShops) {
        if (state.shops && state.shops.length) {
          const { SYNCING } = SYNC_ORDER_STATUS;
          const syncInProgressShops = state.shops
            .filter(shop => shop.current_status === SYNCING)
            .map(shop => shop.name);
          commit('SET_SYNC_IN_PROGRESS_SHOPS', syncInProgressShops);
        }
      }
    } catch (error) {
      commit('SET_FETCH_REGISTERED_ACCOUNT_STATUS', false);
    }
  },
  async registerAccount({ commit, dispatch }, data) {
    commit('SET_REGISTER_ACCOUNT_STATUS', null);
    try {
      const response = await shopifyAuthApis.registerAccount(data);
      dispatch('setRegisteredAccount', response);
      commit('SET_REGISTER_ACCOUNT_STATUS', true);
    } catch (error) {
      commit('SET_REGISTER_ACCOUNT_ERROR', error);
      commit('SET_REGISTER_ACCOUNT_STATUS', false);
    }
  },
  async unregisterAccount({ commit, dispatch }, params) {
    commit('SET_UNREGISTER_ACCOUNT_STATUS', null);
    try {
      const response = await extRegisterApis.unregisterAccount(params);
      dispatch('setRegisteredAccount', response);
      commit('SET_UNREGISTER_ACCOUNT_STATUS', true);
    } catch (error) {
      commit('SET_UNREGISTER_ACCOUNT_ERROR', error);
      commit('SET_UNREGISTER_ACCOUNT_STATUS', false);
    }
  },
  async enqueueSyncOrders({ commit }, shopNames) {
    if (!shopNames || !shopNames.length) {
      return;
    }
    commit('SET_ENQUEUE_SYNC_ORDERS_STATUS', { shopNames, status: null });
    try {
      const response = await extOrdersApis.enqueueSyncOrders({ shops: shopNames });
      const { data, meta } = response || {};
      if (meta && meta.http_status === 200 && data.length) {
        const enqueuedShops = data.filter(item => !item.error_code).map(item => item.shop);
        commit('SET_SYNC_IN_PROGRESS_SHOPS', enqueuedShops);
      }
      // await new Promise(resolve => setTimeout(resolve, 3000));
      commit('SET_ENQUEUE_SYNC_ORDERS_STATUS', { shopNames, status: true });
    } catch (error) {
      commit('SET_ENQUEUE_SYNC_ORDERS_STATUS', { shopNames, status: false });
    }
  },
  setRegisteredAccount({ commit }, response) {
    if (response && response.data) {
      const { registered, shops = null } = response.data;
      commit('SET_REGISTERED', registered);
      commit('SET_SHOPS', shops);
    }
  },
};

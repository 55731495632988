import aggregateApis from 'shared_js/api/public/aggregate';
import { isLoggedIn } from 'shared_js/helpers/user-helper';
import { neoTogglePlatform } from 'shared_js/helpers/user-agent-helper';
import { buildToggleParams } from './helpers';
import { SET_TOGGLE_STATUS } from './types';

export default {
  async fetchToggles({ commit, state }, additionalRequests = {}) {
    const { requests, platformRequests, nonPlatformRequests, aggregates } = buildToggleParams({
      state,
      additionalRequests,
      isLoggedIn: isLoggedIn(),
      neoTogglePlatform: neoTogglePlatform(),
      path: window.location.pathname,
    });

    if (Object.keys(aggregates).length > 0) {
      await aggregateApis.aggregate(aggregates).then(response => {
        const { data, meta } = response;

        Object.keys(aggregates).forEach(aggregateKey => {
          if (!['platformRequests', 'nonPlatformRequests'].includes(aggregateKey)) {
            const { [aggregateKey]: currRequestData } = data || {};
            const { [aggregateKey]: currRequestMeta } = meta || {};
            const { callback } = additionalRequests[aggregateKey] || {};
            if (currRequestData && currRequestMeta && callback) {
              const apiResult = { data: currRequestData, meta: currRequestMeta };
              callback({ apiResult });
            }
            return;
          }
          const result = data[aggregateKey] || [];
          result.forEach(neoToggle => {
            const key = Object.keys(requests).find(k => requests[k] === neoToggle.id);
            commit(SET_TOGGLE_STATUS, {
              status: neoToggle.active,
              key,
            });
          });
          const currentRequests = aggregateKey === 'platformRequests' ? platformRequests : nonPlatformRequests;
          currentRequests
            .filter(toggleKey => {
              const resultIds = result.map(d => d.id);

              return !resultIds.includes(toggleKey);
            })
            .forEach(toggleKey => {
              const key = Object.keys(requests).find(k => requests[k] === toggleKey);
              commit(SET_TOGGLE_STATUS, {
                status: false,
                key,
              });
            });
        });
      });
    }
  },
};

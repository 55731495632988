import { API_BASE_URL } from 'shared_js/constant/bukasend';

const apiPath = '/open-shipments/promotions/availability';
const url = `${API_BASE_URL}${apiPath}`;

const interfaces = {
  getPromoBudgetInfo() {
    return this.get(url, 'public');
  },
};

export default interfaces;

const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce(
    (obj, item) => ({
      ...obj,
      [item[key]]: item,
    }),
    initialValue,
  );
};

const chunkArray = (inputArray, perPage) =>
  [...inputArray].reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perPage);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

module.exports = {
  convertArrayToObject,
  chunkArray,
};

/* eslint-disable */
export const getUserId = () =>
  global.serverApp && global.serverApp.user ? global.serverApp.user.id : global.USER ? global.USER.id : '';
export const getUserId36 = () => getUserId().toString(36);
export const getUsername = () =>
  global.serverApp && global.serverApp.user ? global.serverApp.user.username : global.USER ? global.USER.username : '';
export const getUserFullName = () =>
  global.serverApp && global.serverApp.user ? global.serverApp.user.name : global.USER ? global.USER.name : '';
export const getAvatarURL = () =>
  global.serverApp && global.serverApp.user
    ? global.serverApp.user.avatar.url
    : global.USER
    ? global.USER.avatarURL
    : '';
export const isLoggedIn = () => !!getUserId36();
export const getUserIdentity = () => (global.serverApp ? global.serverApp.userIdentity : global.USER.identity);
export const getUserRole = () =>
  global.serverApp && global.serverApp.user ? global.serverApp.user.role : global.USER ? global.USER.role : '';

export const generateSessionId = () => {
  const generateRandomBetween = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
  const timestamp = Date.now();
  const randomNumber = generateRandomBetween(1000, 9999);
  const userId = getUserId() || '';
  return `${timestamp}${randomNumber}${userId}`;
};

export default {
  getUserId,
  getUserId36,
  isLoggedIn,
  generateSessionId,
  getUserIdentity,
  getUserRole,
};

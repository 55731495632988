export default {
  courierCategories: [],
  fetchCourierCategoryStatus: false,
  couriers: [],
  fetchCourierStatus: false,
  courierError: null,
  hashCouriers: {},
  fetchCourierContactsStatus: false,
  courierContacts: null,
  excludePickupFailedCouriers: null,
  courierOpsTimeConfig: null,
  backendCourierOpsTimeConfig: {},
  registeredCouriers: null,
  pickupGuaranteeConfig: null,
  codCouriersCount: {
    dropoff: null,
    pickup: null,
  },
};

import { API_BASE_URL } from 'shared_js/constant/bukasend';

const url = `${API_BASE_URL}/open-shipments/shopify/auth`;

const interfaces = {
  generateOAuthUrl(data) {
    return this.post(`${url}/generate-url`, 'public', { data });
  },
  registerAccount(data) {
    return this.post(`${url}/register`, 'public', { data });
  },
};

export default interfaces;

import { API_BASE_URL } from 'shared_js/constant/bukasend';
import getBukalapakIdentityHeader from 'shared_js/helpers/bl-identity-helper';

const PREFIX_API_URL = `${API_BASE_URL}/open-shipments/`;

const interfaces = {
  getConfiguration() {
    const headers = getBukalapakIdentityHeader();
    return this.get(`${PREFIX_API_URL}configurations`, 'public', { headers });
  },
  getInformation() {
    return this.get(`${PREFIX_API_URL}informations`, 'public');
  },
  updateOnboarding(data) {
    return this.patch(`${PREFIX_API_URL}onboardings`, 'public', { data });
  },
  getMembershipPromotions(params) {
    return this.get(`${PREFIX_API_URL}membership-promotions`, 'public', { params });
  },
};

export default interfaces;

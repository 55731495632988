import { WEIGHT_UNITS } from 'shared_js/constant/bukasend';
import { IS_SSR } from 'shared_js/constant/general';
import { track } from './config';

function getReferrer(path) {
  if (IS_SSR) {
    return path;
  }

  return path ? `${window.location.origin}${path}` : document.referrer;
}

function getWeightUnitParam({ weight_unit: weightUnit }) {
  return weightUnit === WEIGHT_UNITS.GR.value ? 'gram' : 'kilogram';
}

export default {
  getCourierService(type) {
    return type === 'pickup' ? 'pick_up' : 'drop_off';
  },
  bukasendMainPage(type, referrer) {
    track('bukasend_main_page', {
      search_destination_type: type,
      referrer: getReferrer(referrer),
    });
  },
  bukasendSearchKeyword(keyword, action, suggestionOrder, referrer) {
    track('bukasend_search_keyword', {
      action,
      search_keyword: keyword,
      suggestion_order: suggestionOrder,
      referrer: getReferrer(referrer),
    });
  },
  bukasendSearchMaps(action, currentLocation, firstLocation, referrer) {
    const { address, latitude, longitude, postCode, detailAddress } = currentLocation;

    const trackerParams = {
      action,
      address,
      latitude,
      longitude,
      postal_code: postCode,
      address_detail: detailAddress,
    };

    if (firstLocation) {
      trackerParams.address_before = firstLocation.address;
      trackerParams.postal_code_before = firstLocation.postCode || '';
      trackerParams.address_detail_before = firstLocation.detailAddress;
      trackerParams.default_latitude = firstLocation.latitude;
      trackerParams.default_longitude = firstLocation.longitude;
    }

    track('bukasend_search_maps', {
      ...trackerParams,
      referrer: getReferrer(referrer),
    });
  },
  bukasendSendPackage(action, listOrder, referrer) {
    track('bukasend_send_package', {
      action,
      list_order: listOrder,
      referrer: getReferrer(referrer),
    });
  },
  bukasendSendPackageDetails(params) {
    track('bukasend_send_package_details', params);
  },
  bukasendSummaryOrder(action, listOrder = 0, referrer) {
    track('bukasend_summary_order', {
      action,
      list_order: listOrder,
      referrer: getReferrer(referrer),
    });
  },
  bukasendSummaryOrderDetail(params) {
    params.send_method = this.getCourierService(params.send_method);

    track('bukasend_summary_order_detail', params);
  },
  bukasendLandingPage(action, referrer) {
    track('bukasend_landing_page', {
      action,
      referrer: getReferrer(referrer),
    });
  },
  bukasendPriceChecking(action, origin, destination, weightPackage) {
    track('bukasend_price_checking', {
      action,
      origin,
      destination,
      weight_package: weightPackage,
    });
  },
  bukasendHistoryTransactions(action) {
    track('bukasend_history_transactions', {
      action,
    });
  },
  bukasendDetailTransactions(action) {
    track('bukasend_detail_transactions', {
      action,
    });
  },
  bukasendStatusTransactions(action, bookingCode, sendingMethod) {
    track('bukasend_status_transactions', {
      action,
      booking_code: bookingCode,
      sending_method: this.getCourierService(sendingMethod),
    });
  },
  bukasendGenerateBookingCode(action, bookingCode, sendingMethod) {
    track('bukasend_generate_booing_code', {
      action,
      booking_code: bookingCode,
      sending_method: this.getCourierService(sendingMethod),
    });
  },
  bukasendOnboardBulkUpload(action) {
    track('bukasend_onboard_bulk_upload', { action });
  },
  bukasendAddressRecipientRevamp(params) {
    track('bukasend_address_recipient_revamp', params);
  },
  bukasendAddressRecipientRevampDialog(action, inputValue, autoFilled) {
    track('bukasend_address_recipient_revamp_dialog', {
      action,
      input_value: inputValue,
      auto_filled: autoFilled,
    });
  },
  bukasendBulkPrintResi(action, numberResi) {
    track('bukasend_bulk_print_resi', {
      action,
      number_resi: numberResi,
    });
  },
  bukasendBulkUploadMainScreen(action, errorMessage) {
    track('bukasend_bulk_upload_main_screen', {
      action,
      error_message: errorMessage,
    });
  },
  buyerReturnWithBukasendSeeSummary({ returnTransaction, transaction, cashback }) {
    const { items = [], id: transactionId } = returnTransaction;
    const params = {
      cashback: cashback.amount,
      courier_cost: transaction.courier.price,
      courier_service: transaction.courier.service_name,
      item_name: items[0] ? items[0].name : '',
      item_weight_gr: transaction.weight,
      sku_count: items.length,
      marketplace_transaction_id: transactionId,
      weight_type: transaction.volumetric ? 'volumetric' : getWeightUnitParam(transaction),
    };
    track('buyer_return_with_bukasend_see_summary', params);
  },
  buyerReturnWithBukasendProceedToPayment({ returnTransaction, transaction, cashback, bukasendTransactionId }) {
    const { items = [], id: transactionId } = returnTransaction;
    const params = {
      cashback: cashback.amount,
      courier_cost: transaction.courier.price,
      courier_service: transaction.courier.service_name,
      item_name: items[0] ? items[0].name : '',
      item_weight_gr: transaction.weight,
      sku_count: items.length,
      marketplace_transaction_id: transactionId,
      weight_type: transaction.volumetric ? 'volumetric' : getWeightUnitParam(transaction),
      bukasend_transaction_id: bukasendTransactionId,
    };
    track('buyer_return_with_bukasend_proceed_to_payment', params);
  },
  sellerReturnWithBukasendSeeSummary({
    returnTransaction,
    transaction,
    cashback,
    referrer,
    timeMetric,
    solution,
    sessionId,
  }) {
    const { items = [], id: transactionId, returns } = returnTransaction;
    const { buyer_id: buyerId } = returns || {};
    const { type } = solution;
    const solutionType = ['addition', 'replacement'].includes(type) ? `item_${type}` : type;
    const params = {
      marketplace_buyer_id: buyerId ? Number(buyerId).toString(36) : '',
      solution_type: solutionType,
      cashback: cashback.amount,
      cashback_type: cashback.type,
      courier_cost: transaction.courier.price,
      courier_service: transaction.courier.service_name,
      item_name: items[0] ? items[0].name : '',
      item_weight_gr: transaction.weight,
      weight_type: transaction.volumetric ? 'volumetric' : getWeightUnitParam(transaction),
      city_origin: transaction.from.city,
      city_destination: transaction.to.city,
      marketplace_transaction_id: transactionId,
      referrer: String(getReferrer(referrer)),
      screen_name: 'booking_form',
      session_id: sessionId,
      start_time: timeMetric.start,
      end_time: timeMetric.end,
      time_spent: parseInt((timeMetric.end - timeMetric.start) / 1000, 10),
    };
    track('seller_return_with_bukasend_see_summary', params);
  },
  sellerReturnWithBukasendClickBack({ returnTransaction, referrer, solution, screenName, sessionId }) {
    const { id: transactionId, returns } = returnTransaction;
    const { buyer_id: buyerId } = returns || {};
    const { type } = solution;
    const solutionType = ['addition', 'replacement'].includes(type) ? `item_${type}` : type;
    const params = {
      marketplace_transaction_id: transactionId,
      marketplace_buyer_id: buyerId ? Number(buyerId).toString(36) : '',
      solution_type: solutionType,
      screen_name: screenName,
      referrer: String(getReferrer(referrer)),
      session_id: sessionId,
    };
    track('seller_return_with_bukasend_click_back', params);
  },
  sellerReturnWithBukasendChooseAddress({
    returnTransaction,
    transaction,
    timeMetric,
    solution,
    referrer,
    senderAddressType,
    sessionId,
  }) {
    const { id: transactionId, returns } = returnTransaction;
    const { buyer_id: buyerId } = returns || {};
    const { type } = solution;
    const solutionType = ['addition', 'replacement'].includes(type) ? `item_${type}` : type;
    const params = {
      sender_address_type: senderAddressType,
      city_origin: transaction.from.city,
      city_destination: transaction.to.city,
      marketplace_transaction_id: transactionId,
      marketplace_buyer_id: buyerId ? Number(buyerId).toString(36) : '',
      solution_type: solutionType,
      referrer: String(getReferrer(referrer)),
      session_id: sessionId,
      screen_name: 'sender_address_list',
      start_time: timeMetric.start,
      end_time: timeMetric.end,
      time_spent: parseInt((timeMetric.end - timeMetric.start) / 1000, 10),
    };
    track('seller_return_with_bukasend_choose_address', params);
  },
  sellerReturnWithBukasendProceedToPayment({
    cashback,
    returnTransaction,
    transaction,
    timeMetric,
    solution,
    referrer,
    bukasendTransactionId,
    sessionId,
  }) {
    const { items = [], id: transactionId, returns } = returnTransaction;
    const { buyer_id: buyerId } = returns || {};
    const { type } = solution;
    const solutionType = ['addition', 'replacement'].includes(type) ? `item_${type}` : type;
    const params = {
      marketplace_buyer_id: buyerId ? Number(buyerId).toString(36) : '',
      solution_type: solutionType,
      cashback: cashback.amount,
      cashback_type: cashback.type,
      courier_cost: transaction.courier.price,
      courier_service: transaction.courier.service_name,
      item_name: items[0] ? items[0].name : '',
      item_weight_gr: transaction.weight,
      weight_type: transaction.volumetric ? 'volumetric' : getWeightUnitParam(transaction),
      city_origin: transaction.from.city,
      city_destination: transaction.to.city,
      bukasend_transaction_id: bukasendTransactionId,
      marketplace_transaction_id: transactionId,
      referrer: String(getReferrer(referrer)),
      screen_name: 'summary_form',
      session_id: sessionId,
      start_time: timeMetric.start,
      end_time: timeMetric.end,
      time_spent: parseInt((timeMetric.end - timeMetric.start) / 1000, 10),
    };
    track('seller_return_with_bukasend_proceed_to_payment', params);
  },
  sellerReturnWithBukasendClickCTA({
    returnTransaction,
    solution,
    referrer,
    bukasendTransactionId,
    bukasendState,
    ctaType,
  }) {
    const { transaction_id: transactionId, buyer_id: buyerId } = returnTransaction;
    const { type } = solution;
    const solutionType = ['addition', 'replacement'].includes(type) ? `item_${type}` : type;
    const params = {
      bukasend_transaction_state: bukasendState,
      cta_type: ctaType,
      bukasend_transaction_id: bukasendTransactionId,
      marketplace_transaction_id: transactionId,
      marketplace_buyer_id: buyerId ? Number(buyerId).toString(36) : '',
      solution_type: solutionType,
      referrer: String(getReferrer(referrer)),
      screen_name: 'auto-sheet',
    };
    track('seller_return_with_bukasend_click_cta', params);
  },
  trackEvent(eventName, params) {
    if (!IS_SSR) {
      track(eventName, params);
    }
  },
};

import { API_BASE_URL } from 'shared_js/constant/bukasend';

const aggregateURL = `${API_BASE_URL}/aggregate`;

const interfaces = {
  aggregate(aggregate, params = {}) {
    return this.post(aggregateURL, 'public', {
      data: { aggregate },
      ...params,
    });
  },
};

export default interfaces;

/* eslint-disable */
import wrapApiHandler from './wrapApiHandler';
import handlerHooks from './hooks';

const prepareClient = (interfaces, interfaceKey) => {
  let wrap;
  let clientInterface;

  if (global.serverApp) {
    wrap = require('../../server/interface-wrapper');
    clientInterface = wrap(global.serverApp.secureClient, interfaces);
  } else {
    ({ wrap } = global.API);
    clientInterface = wrap(interfaces, interfaceKey);
  }
  return wrapApiHandler(clientInterface, handlerHooks);
};

export default prepareClient;

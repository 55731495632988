export default {
  configuration: null,
  fetchConfigStatus: null,

  information: null,
  fetchInformationStatus: null,

  sessionId: '',
  renderTime: new Date().getTime(),

  manualBookingCourierServices: null,
  customFieldWhitelistUsers: null,
  courierImageConfig: null,
  packageContentCategories: null,

  onboardings: null,
  promoConfig: null,
  printConfig: null,

  initialized: false,
  entryPoint: '',
  visitedRoutes: [],
  cashbackConfig: null,
  grabSLAConfig: null,
  weightTncConfig: null,
  discrepancyDetails: null,
  miscConfig: null,
  rebookConfig: null,
  rolePermission: null,
  cancellationConfig: null,
  ondemandCourierServices: null,
  voucherSuggestions: null,
  voucherConfig: null,
  memberships: null,
  ongkirGokilConfig: null,
  kyc: null,
  encyclopediaAgentConfig: null,
  courierLandingConfig: null,
  codConfig: null,
  benefitsConfig: null,
  courierSelectionConfig: null,
  partnerSecret: null,

  bannerSliders: null,
  membershipBenefits: {
    pickup: {},
    dropoff: {},
    cod: {},
  },
  courierTags: null,
};

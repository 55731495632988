import { API_BASE_URL } from 'shared_js/constant/bukasend';

const apiPath = '/open-shipments/recipient-addresses';
const url = `${API_BASE_URL}${apiPath}`;

const interfaces = {
  getRecipientAddresses(params = {}) {
    return this.get(url, 'public', { params });
  },
  createRecipientAddress(data) {
    return this.post(`${url}`, 'public', { data });
  },
  updateRecipientAddress(data) {
    return this.patch(`${url}/${data.id}`, 'public', { data });
  },
  deleteRecipientAddress(id) {
    return this.delete(`${url}/${id}`, 'public');
  },
};

export default interfaces;

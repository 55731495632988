import addressApis from 'shared_js/api/public/addresses';
import senderAddressApis from 'shared_js/api/public/sender-addresses';
import aggregateApis from 'shared_js/api/public/aggregate';
import cloneDeep from 'lodash.clonedeep';
import { isLoggedIn } from 'shared_js/helpers/user-helper';

export default {
  async fetchAddresses({ commit, dispatch, state, rootState }, forceUpdate = false) {
    if (!isLoggedIn()) {
      commit('SET_ADDRESS', []);
      commit('SET_FETCH_ADDRESS_STATUS', true);
      return;
    }

    if ([null, true].includes(state.fetchAddressStatus) && !forceUpdate) {
      return;
    }

    commit('SET_FETCH_ADDRESS_STATUS', null);

    try {
      const response = await addressApis.getAddresses();
      let status = false;

      if (response.meta.http_status === 200) {
        status = true;
        const { data } = response;

        commit('SET_ADDRESS', data);
      }
      commit('SET_FETCH_ADDRESS_STATUS', status);
    } catch (e) {
      commit('SET_FETCH_ADDRESS_STATUS', false);
    }

    if (rootState.toggle.toggleSenderGeolocationFlagEnabled && state.fetchAddressStatus) {
      await dispatch('fetchBulkAdditionalAttributes');
    }
  },
  async fetchProvinces({ commit, state }) {
    if ([null, true].includes(state.fetchProvincesStatus)) {
      return;
    }

    commit('SET_FETCH_PROVINCES_STATUS', null);

    try {
      const response = await addressApis.getProvinces({
        country: 'Indonesia',
      });
      let status = false;

      if (response.meta.http_status === 200) {
        status = true;
        const { data } = response;

        commit('SET_PROVINCES', data);
      }
      commit('SET_FETCH_PROVINCES_STATUS', status);
    } catch (e) {
      commit('SET_FETCH_PROVINCES_STATUS', false);
    }
  },
  async fetchCities({ commit, state }, { province }) {
    if (state.fetchCitiesStatus === null || state.cities[province]) {
      return;
    }

    commit('SET_FETCH_CITIES_STATUS', null);

    try {
      const response = await addressApis.getCities({
        province,
      });
      let status = false;

      if (response.meta.http_status === 200) {
        status = true;
        const { data } = response;

        commit('SET_CITIES', {
          province,
          cities: data,
        });
      }
      commit('SET_FETCH_CITIES_STATUS', status);
    } catch (e) {
      commit('SET_FETCH_CITIES_STATUS', false);
    }
  },
  async fetchDistricts({ commit, state }, { province, city, allowMultiple = false }) {
    if ((!allowMultiple && state.fetchDistrictStatus === null) || state.districts[city]) {
      return;
    }

    commit('SET_FETCH_DISTRICTS_STATUS', null);

    try {
      const response = await addressApis.getDistricts({
        province,
        city,
      });
      let status = false;

      if (response.meta.http_status === 200) {
        status = true;
        const { data } = response;

        commit('SET_DISTRICTS', {
          city,
          districts: data,
        });
      }
      commit('SET_FETCH_DISTRICTS_STATUS', status);
    } catch (e) {
      commit('SET_FETCH_DISTRICTS_STATUS', false);
    }
  },
  async fetchPostalCodes({ commit, state }, { province, city, district, allowMultiple }) {
    if ((!allowMultiple && state.fetchPostalCodesStatus === null) || state.postalCodes[district]) {
      return;
    }

    commit('SET_FETCH_POSTAL_CODES_STATUS', null);

    try {
      const response = await addressApis.getPostalCodes({
        province,
        city,
        district,
      });
      let status = false;

      if (response.meta.http_status === 200) {
        status = true;
        const { data } = response;

        commit('SET_POSTAL_CODES', {
          district,
          postalCodes: data,
        });
      }
      commit('SET_FETCH_POSTAL_CODES_STATUS', status);
    } catch (e) {
      commit('SET_FETCH_POSTAL_CODES_STATUS', false);
    }
  },
  async deleteAddress({ commit, rootState }, id) {
    try {
      await addressApis.deleteAddress(id);

      commit('DELETE_ADDRESS', id);
      if (rootState.toggle.toggleSenderGeolocationFlagEnabled) {
        commit('DELETE_GEOLOCATION_FLAG_HASH', id);
      }
    } catch (e) {
      throw e;
    }
  },
  async updateAdditionalAttributes({ commit }, params) {
    try {
      const response = await senderAddressApis.updateAdditionalAttributes(params);
      if (response && response.data) {
        commit('SET_GEOLOCATION_FLAG_HASH', response.data);
      }
    } catch (err) {
      throw err;
    }
  },
  async fetchBulkAdditionalAttributes({ commit, state }) {
    if (state.fetchBulkAdditionalAttributesStatus === null) {
      return;
    }

    const { addresses, geolocationFlagHash } = state;
    const flaggedAddresses = cloneDeep(addresses);
    const aggregates = {};

    // re-map existing geolocation flags into addresses
    flaggedAddresses.forEach(address => {
      if (geolocationFlagHash[address.id] !== undefined) {
        address.geolocation_set_by_user = geolocationFlagHash[address.id];
      } else {
        aggregates[address.id] = {
          method: 'GET',
          path: `/open-shipments/sender-addresses/${address.id}/additional-attributes`,
        };
      }
    });

    if (!Object.keys(aggregates).length) {
      commit('SET_ADDRESS', flaggedAddresses);
      commit('SET_FETCH_BULK_ADDITIONAL_ATTRIBUTES_STATUS', true);
      return;
    }

    commit('SET_FETCH_BULK_ADDITIONAL_ATTRIBUTES_STATUS', null);

    try {
      const response = await aggregateApis.aggregate(aggregates);

      // map geolocation flag from response into addresses
      if (response && response.data && response.meta) {
        flaggedAddresses.forEach(address => {
          const currentMeta = response.meta[address.id] || {};
          const currentData = response.data[address.id];
          if (currentMeta.http_status === 200 && currentData) {
            commit('SET_GEOLOCATION_FLAG_HASH', currentData);
            address.geolocation_set_by_user = currentData.geolocation_set_by_user;
          }
        });
      }

      commit('SET_ADDRESS', flaggedAddresses);
      commit('SET_FETCH_BULK_ADDITIONAL_ATTRIBUTES_STATUS', true);
    } catch (err) {
      commit('SET_ADDRESS', flaggedAddresses);
      commit('SET_FETCH_BULK_ADDITIONAL_ATTRIBUTES_STATUS', false);
      throw err;
    }
  },
};

const curry = require('../curry-helper');

const isTrxUseCOD = transaction => transaction.codChecked && transaction.codAmount > 0;

const getCodAdminFee = transaction => {
  const minCodAdminFee = curry(['courier', 'cod_rules', 'min_cod_admin_fee'], transaction) || 0;
  const percentageCodAdminFee = curry(['courier', 'cod_rules', 'percentage_cod_admin_fee'], transaction) || 0;

  return isTrxUseCOD(transaction)
    ? Math.max(Math.round((transaction.codAmount * percentageCodAdminFee) / 100), minCodAdminFee)
    : 0;
};

module.exports = {
  isTrxUseCOD,
  getCodAdminFee,
};

import cloneDeep from 'lodash.clonedeep';
import { DEFAULT_TRANSACTION } from '../constants';

export default {
  activeIdx: null,
  saveTransactionStatus: false,
  createBulkTransactionStatus: false,
  transaction: cloneDeep(DEFAULT_TRANSACTION),
  transactions: [],
  showPackageForm: false,
  transactionType: 'transaction',
  loadedFromStorage: false,
  firstRenderTime: null,
  previousClick: '',
  saveDraftStatus: false,
  groupId: '',
  formSelectedVoucher: null,
  voucherValidateStatus: '',
};

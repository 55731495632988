import { IS_DEV_MODE } from 'shared_js/constant/bukasend';

const errorReporter = {
  initialized: false,
  init() {
    if (!this.initialized) {
      this.initialized = true;
    }
  },
  defaultErrorCatcher(error) {
    this.init();

    if (IS_DEV_MODE) {
      console.log('ERROR DETECTED:', error);
    }
  },
  apiErrorCatcher(error, options) {
    if (!error.response) {
      this.defaultErrorCatcher(error, options);
    }
    return Promise.reject(error);
  },
};

export default errorReporter;

export default {
  SET_RECIPIENT_ADDRESS(state, data) {
    const recipientAddresses = state.recipientAddresses || [];
    state.recipientAddresses = recipientAddresses.concat(data);
  },
  SET_TOTAL_RECIPIENT_ADDRESS(state, total) {
    state.totalRecipientAddress = total;
  },
  UPDATE_RECIPIENT_ADDRESS(state, data) {
    if (!state.recipientAddresses) {
      state.recipientAddresses = [data];
    } else {
      const index = state.recipientAddresses.findIndex(r => r.id === data.id);

      if (index !== -1) {
        state.recipientAddresses.splice(index, 1);
      }

      state.recipientAddresses.unshift(data);
    }
  },
  SET_FETCH_RECIPIENT_ADDRESS_STATUS(state, status) {
    state.fetchRecipientAddressStatus = status;
  },
  SET_SEARCH_KEYWORD(state, keyword) {
    state.searchKeyword = keyword || '';
  },
  SET_SEARCH_ADDRESS(state, data) {
    state.searchAddresses = data;
  },
  TOGGLE_RECIPIENT_LOCATION(state) {
    state.showRecipientLocation = !state.showRecipientLocation;
  },
  SET_RECIPIENT_LOCATION(state, data) {
    state.recipientLocation = {
      ...state.recipientLocation,
      ...data,
    };
  },
  DELETE_RECIPIENT_ADDRESS(state, index) {
    state.recipientAddresses.splice(index, 1);
    state.totalRecipientAddress -= 1;
  },
};

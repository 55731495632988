const { isMobile } = require('../url-helper');
const curry = require('../curry-helper');

const getUserAgent = () => {
  const serverUserAgent = curry(['serverApp', 'userAgent'], global);
  const clientUserAgent = curry(['userAgent'], global.navigator);

  return serverUserAgent || clientUserAgent || '';
};

const checkUserAgent = pattern => {
  const ua = getUserAgent();

  return new RegExp(pattern).test(ua.toLowerCase());
};

const BLMitraVersion = () => {
  const ua = getUserAgent();
  const userAgent = ua.trim().split(' ');
  const version = userAgent[userAgent.length - 2] || '';

  return parseInt(version.toString().substr(0, 7), 10);
};

const BLIosVersion = () => {
  const userAgent = getUserAgent();
  const [, version] = userAgent.match(/BLIOS\/(\d+)/) || [];
  return parseInt(version, 10) || 0;
};

const BLAndroidVersion = () => {
  const userAgent = getUserAgent();
  const [, version] = userAgent.match(/BLAndroid\/(\d+)/) || [];
  return parseInt(version, 10) || 0;
};

const getBrowserInfo = () => {
  if (!navigator) {
    return '';
  }
  // SOURCE: https://stackoverflow.com/questions/5916900/how-can-you-detect-the-version-of-a-browser
  const ua = getUserAgent();
  let tem;
  let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return `IE ${tem[1] || ''}`;
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null)
      return tem
        .slice(1)
        .join(' ')
        .replace('OPR', 'Opera');
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  tem = ua.match(/version\/(\d+)/i);
  if (tem != null) M.splice(1, 1, tem[1]);
  return M.join(' ');
};

const isBLAndroid = () => checkUserAgent('blandroid');
const isBLIos = () => checkUserAgent('blios');
const isBLMitra = () => checkUserAgent('blmitraandroid');
const isBLBukasend = () => checkUserAgent('blbukasend');

const platform = () => {
  const isMweb = isMobile();
  let result = 'desktop';

  if (isMweb) {
    if (isBLAndroid()) {
      result = 'android';
    } else if (isBLIos()) {
      result = 'ios';
    } else if (isBLMitra()) {
      result = 'mitra';
    } else {
      result = 'mweb';
    }
  }

  return result;
};

const isDesktop = () => platform() === 'desktop';

const neoTogglePlatform = () => {
  const mappings = {
    desktop: 'bl_web',
    mweb: 'bl_mobile_web',
    android: 'bl_app_android',
    ios: 'bl_app_ios',
    mitra: 'bl_app_mitra_android',
  };

  return mappings[platform()];
};

const cashbackCopyAgent = (isAgent, value) => {
  if (isAgent) {
    return value.replace(/Cashback/, 'Komisi').replace(/cashback/, 'komisi');
  }
  return value;
};

module.exports = {
  checkUserAgent,
  BLMitraVersion,
  BLIosVersion,
  BLAndroidVersion,
  isBLAndroid,
  isBLIos,
  isBLMitra,
  platform,
  isDesktop,
  neoTogglePlatform,
  cashbackCopyAgent,
  isBLBukasend,
  getBrowserInfo,
};

import recipientApis from 'shared_js/api/public/recipient-addresses';
import { isLoggedIn } from 'shared_js/helpers/user-helper';

export default {
  async createRecipientAddress({ commit, state }, data) {
    try {
      const response = await recipientApis.createRecipientAddress(data);
      commit('UPDATE_RECIPIENT_ADDRESS', response.data);
      commit('SET_TOTAL_RECIPIENT_ADDRESS', state.totalRecipientAddress + 1);
      return response;
    } catch (err) {
      throw new Error(err);
    }
  },
  async fetchRecipientAddresses({ commit, state }, params = {}) {
    if (!isLoggedIn()) {
      commit('SET_RECIPIENT_ADDRESS', []);
      commit('SET_TOTAL_RECIPIENT_ADDRESS', 0);
      commit('SET_FETCH_RECIPIENT_ADDRESS_STATUS', true);
      return;
    }

    if (state.recipientAddresses !== null && state.recipientAddresses.length === state.totalRecipientAddress) {
      return;
    }

    const offset = state.recipientAddresses ? state.recipientAddresses.length : 0;

    commit('SET_FETCH_RECIPIENT_ADDRESS_STATUS', null);

    try {
      const parameter = {
        offset,
        limit: params.limit || 10,
      };
      const response = await recipientApis.getRecipientAddresses(parameter);
      let status = false;
      let data = null;
      let total = 0;

      if (response.meta.http_status === 200) {
        status = true;
        ({ data } = response);
        total = response.meta.total || 0;
      }
      commit('SET_RECIPIENT_ADDRESS', data);
      commit('SET_TOTAL_RECIPIENT_ADDRESS', total);
      commit('SET_FETCH_RECIPIENT_ADDRESS_STATUS', status);
    } catch (e) {
      commit('SET_FETCH_RECIPIENT_ADDRESS_STATUS', false);
    }
  },
};

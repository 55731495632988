import { IDENTITY } from 'shared_js/constant/general';

export const getOTPHeader = (otp = '', channel = 'sms') => {
  const cachedOTPKey = window.localStorage.getItem('otp_key');
  const browserFingerprint = window.localStorage.getItem('browser_fp');
  let headers = {
    'Bukalapak-OTP-Device-ID': IDENTITY,
    'Bukalapak-OTP': otp,
    'Bukalapak-OTP-Method': channel,
  };
  if (cachedOTPKey) {
    headers = Object.assign(headers, { 'Bukalapak-OTP-Key': cachedOTPKey });
    delete headers['Bukalapak-OTP'];
  }
  if (browserFingerprint) {
    headers = Object.assign(headers, { 'Bukalapak-Device-Fingerprint': browserFingerprint });
  }
  return headers;
};

export default {
  getOTPHeader,
};

import experimentApis from 'shared_js/api/exclusive/experiments';
import { mapState, mapGetters } from 'vuex';
import { getUserId, isLoggedIn } from 'shared_js/helpers/user-helper';
import { LOCAL_STORAGE_KEYS } from 'shared_js/constant/local_storage';

export default {
  data() {
    return {
      generatedUserId: '',
    };
  },
  computed: {
    getUserId,
    isLoggedIn,
    ...mapState('configuration', ['sessionId']),
    ...mapState('form', ['activeIdx']),
    ...mapGetters('form', ['packageNumber']),
    ...mapGetters('experiment', [
      'bukasendCleanupCTAExperimentKey',
      'bukasendCleanupCTAExperimentStatus',
      'homeVoucherBannerExperimentKey',
      'homeVoucherBannerExperimentStatus',
      'bukasendCostCheckParentPageExperimentKey',
      'bukasendCostCheckParentPageExperimentStatus',
    ]),
  },
  methods: {
    viewBookingFormCleanupCTAExperiment() {
      if (!this.bukasendCleanupCTAExperimentStatus) {
        return;
      }

      experimentApis.scoreMetric({
        experimentName: this.bukasendCleanupCTAExperimentKey,
        participant: this.getUserId,
        metric: 'view_booking_form',
        value: 1,
      });
    },
    sendHomeVoucherBannerExperimentMetric(metric) {
      if (!this.homeVoucherBannerExperimentStatus) {
        return;
      }

      experimentApis.scoreMetric({
        experimentName: this.homeVoucherBannerExperimentKey,
        participant: this.getUserId,
        value: 1,
        metric,
      });
    },
    getGeneratedUserId() {
      if (!this.generatedUserId) {
        const key = LOCAL_STORAGE_KEYS.LOGOUT_USER_GENERATED_ID;
        let generatedUserId = localStorage.getItem(key);

        if (!generatedUserId) {
          generatedUserId = `RANDOM_${this.sessionId}`;
          localStorage.setItem(key, generatedUserId);
        }

        this.generatedUserId = generatedUserId;
      }

      return this.generatedUserId;
    },
    async sendCostCheckParentPageExperimentMetric(metric) {
      const isNotValid =
        ['click_button_register', 'click_button_cek_ongkir'].includes(metric) && this.$route.name !== 'landing_page';

      if (!this.bukasendCostCheckParentPageExperimentStatus || isNotValid) {
        return;
      }

      await experimentApis.scoreMetric({
        experimentName: this.bukasendCostCheckParentPageExperimentKey,
        participant: this.getGeneratedUserId(),
        value: 1,
        metric,
      });
    },
  },
};
